import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchSubjectDetails = createAsyncThunk(
  "admin/detail-subject",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subjectDetailURL.replace("{id}", id),
        method: "GET",
      });

      let data = await response;
      if (data.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const subjectDetailsSlice = createSlice({
  name: "subject-details",
  initialState: {
    subjectDetails: {},
    fldFetching: false,
    fldSuccess: false,
    fldError: false,
    fldErrorMessage: "",
  },
  reducers: {
    clearFldState: (state) => {
      state.fldError = false;
      state.fldSuccess = false;
      state.fldFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSubjectDetails.fulfilled]: (state, { payload }) => {
      state.subjectDetails = payload.data;

      state.fldFetching = false;
      state.fldSuccess = true;
      return state;
    },
    [fetchSubjectDetails.rejected]: (state, action) => {
      state.fldFetching = false;
      state.fldError = true;
      state.fldErrorMessage = action?.payload;
    },
    [fetchSubjectDetails.pending]: (state) => {
      state.fldFetching = true;
    },
  },
});

export const { clearFldState } = subjectDetailsSlice.actions;

export default subjectDetailsSlice.reducer;
