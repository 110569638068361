import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchCoursePackageDetails = createAsyncThunk(
  "admin/detail-course-package",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.coursePackageDetailsURL.replace("{id}", id),
        method: "GET",
      });

      let data = await response;
      if (data.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const coursePackageDetailsSlice = createSlice({
  name: "course-package-details",
  initialState: {
    coursePackageDetails: {},
    fldFetching: false,
    fldSuccess: false,
    fldError: false,
    fldErrorMessage: "",
  },
  reducers: {
    clearFldState: (state) => {
      state.fldError = false;
      state.fldSuccess = false;
      state.fldFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCoursePackageDetails.fulfilled]: (state, { payload }) => {
      state.coursePackageDetails = payload.data;
      state.fldFetching = false;
      state.fldSuccess = true;
      return state;
    },
    [fetchCoursePackageDetails.rejected]: (state, action) => {
      state.fldFetching = false;
      state.fldError = true;
      state.fldErrorMessage = action?.payload;
    },
    [fetchCoursePackageDetails.pending]: (state) => {
      state.fldFetching = true;
    },
  },
});

export const { clearFldState } = coursePackageDetailsSlice.actions;

export default coursePackageDetailsSlice.reducer;
