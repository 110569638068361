import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const addInitialFee = createAsyncThunk(
  "admin/settings/add-initial-fee",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.initialFeePostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const initialFeeAddSlice = createSlice({
  name: "add-initial-fee",
  initialState: {
    addInitialFeeFetching: false,
    addInitialFeeSuccess: false,
    addInitialFeeError: false,
    addInitialFeeErrorMessage: "",
  },
  reducers: {
    addInitialFeeClearState: (state) => {
      state.addInitialFeeError = false;
      state.addInitialFeeSuccess = false;
      state.addInitialFeeFetching = false;

      return state;
    },
  },
  extraReducers: {
    [addInitialFee.fulfilled]: (state, { payload }) => {
      state.addInitialFeeFetching = false;
      state.addInitialFeeSuccess = true;
      return state;
    },
    [addInitialFee.rejected]: (state, action) => {
      state.addInitialFeeFetching = false;
      state.addInitialFeeError = true;
      state.addInitialFeeErrorMessage = action?.payload;
    },
    [addInitialFee.pending]: (state) => {
      state.addInitialFeeFetching = true;
    },
  },
});

export const { addInitialFeeClearState } = initialFeeAddSlice.actions;

export default initialFeeAddSlice.reducer;
