import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editLeadDetails = createAsyncThunk(
  "admin/lead-edit",
  async ({payload, leadID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.leadDetailsURL.replace("{id}", leadID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const leadEditSlice = createSlice({
  name: "lead-edit",
  initialState: {
    evlFetching: false,
    evlSuccess: false,
    evlError: false,
    evlErrorMessage: "",
  },
  reducers: {
    clearEvlState: (state) => {
      state.evlError = false;
      state.evlSuccess = false;
      state.evlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editLeadDetails.fulfilled]: (state, { payload }) => {
      state.evlFetching = false;
      state.evlSuccess = true;
      return state;
    },
    [editLeadDetails.rejected]: (state, action) => {
      state.evlFetching = false;
      state.evlError = true;
      state.evlErrorMessage = action?.payload;
    },
    [editLeadDetails.pending]: (state) => {
      state.evlFetching = true;
    },
  },
});
export const { clearEvlState } = leadEditSlice.actions;

export default leadEditSlice.reducer;
