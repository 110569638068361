import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editCourse = createAsyncThunk(
  "admin/course-edit",
  async ({ courseID, payload }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.courseSettingsDetailsURL.replace("{id}", courseID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const courseEditSlice = createSlice({
  name: "course-edit",
  initialState: {

    courseEditFetching: false,
    courseEditSuccess: false,
    courseEditError: false,
    courseEditErrorMessage: "",
  },
  reducers: {
    courseEditClearState: (state) => {
      state.courseEditError = false;
      state.courseEditSuccess = false;
      state.courseEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editCourse.fulfilled]: (state, { payload }) => {
      state.courseEditFetching = false;
      state.courseEditSuccess = true;
      return state;
    },
    [editCourse.rejected]: (state, { payload }) => {
      state.courseEditFetching = false;
      state.courseEditError = true;
      state.courseEditErrorMessage = payload?.message;
    },
    [editCourse.pending]: (state) => {
      state.courseEditFetching = true;
    },
  },
});

export const { courseEditClearState } = courseEditSlice.actions;

export default courseEditSlice.reducer;
