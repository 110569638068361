import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchLeadDetails = createAsyncThunk(
  "admin/Lead-details",
  async ({ leadID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.leadDetailsURL.replace("{id}", leadID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const leadDetailsSlice = createSlice({
  name: "Lead-details",
  initialState: {
    leadDetails: {},
    fldFetching: false,
    fldSuccess: false,
    fldError: false,
    fldErrorMessage: "",
  },
  reducers: {
    clearFldState: (state) => {
      state.fldError = false;
      state.fldSuccess = false;
      state.fldFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchLeadDetails.fulfilled]: (state, { payload }) => {
      state.leadDetails = payload.data;

      state.fldFetching = false;
      state.fldSuccess = true;
      return state;
    },
    [fetchLeadDetails.rejected]: (state, action) => {
      state.fldFetching = false;
      state.fldError = true;
      state.fldErrorMessage = action?.payload;
    },
    [fetchLeadDetails.pending]: (state) => {
      state.fldFetching = true;
    },
  },
});
export const { clearFldState } = leadDetailsSlice.actions;

export default leadDetailsSlice.reducer;
