import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchTeacherPaymentHistory = createAsyncThunk(
  "admin/teacher-class-history",
  async ({ limit, offset, teacherId }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.teacherPaymentHistoryURL
          .replace("{teacher_id}", teacherId)
          .replace("{limit}", limit)
          .replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const teacherPaymentHistorySlice = createSlice({
  name: "teacherPaymentHistory-list",
  initialState: {
    teacherPaymentHistoryList: [],
    teacherPaymentHistoryCount: null,
    teacherPaymentHistoryFetching: false,
    teacherPaymentHistorySuccess: false,
    teacherPaymentHistoryError: false,
    teacherPaymentHistoryErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.teacherPaymentHistoryError = false;
      state.teacherPaymentHistorySuccess = false;
      state.teacherPaymentHistoryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTeacherPaymentHistory.fulfilled]: (state, { payload }) => {
      state.teacherPaymentHistoryList = [];
      payload.data.results.forEach((item) => state.teacherPaymentHistoryList.push(item));
      state.teacherPaymentHistoryCount = payload.data.count;

      state.teacherPaymentHistoryFetching = false;
      state.teacherPaymentHistorySuccess = true;
      return state;
    },
    [fetchTeacherPaymentHistory.rejected]: (state, action) => {
      state.teacherPaymentHistoryFetching = false;
      state.teacherPaymentHistoryError = true;
      state.teacherPaymentHistoryErrorMessage = action?.payload;
    },
    [fetchTeacherPaymentHistory.pending]: (state) => {
      state.teacherPaymentHistoryFetching = true;
    },
  },
});

export const { fclClearState } = teacherPaymentHistorySlice.actions;

export default teacherPaymentHistorySlice.reducer;
