import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const excelUpload = createAsyncThunk(
  "admin/settings/excel-upload",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.excelUploadURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const excelUploadSlice = createSlice({
  name: "excel-upload",
  initialState: {
    excelUploadFetching: false,
    excelUploadSuccess: false,
    excelUploadError: false,
    excelUploadErrorMessage: "",
  },
  reducers: {
    excelUploadClearState: (state) => {
      state.excelUploadError = false;
      state.excelUploadSuccess = false;
      state.excelUploadFetching = false;

      return state;
    },
  },
  extraReducers: {
    [excelUpload.fulfilled]: (state, { payload }) => {
      state.excelUploadFetching = false;
      state.excelUploadSuccess = true;
      return state;
    },
    [excelUpload.rejected]: (state, action) => {
      state.excelUploadFetching = false;
      state.excelUploadError = true;
      state.excelUploadErrorMessage = action?.payload;
    },
    [excelUpload.pending]: (state) => {
      state.excelUploadFetching = true;
    },
  },
});

export const { excelUploadClearState } = excelUploadSlice.actions;

export default excelUploadSlice.reducer;
