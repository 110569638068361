import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchStudentCourseList = createAsyncThunk(
  "admin/studentCourse-list",
  async ({limit ,offset ,studentID}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentCourseListURL.replace("{id}",studentID).replace("{limit}", limit).replace("{offset}", offset) ,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const studentCourseListSlice = createSlice({
  name: "studentCourse-list",
  initialState: {
    studentCourseList: [],
    studentCourseListFetching: false,
    studentCourseListSuccess: false,
    studentCourseListError: false,
    studentCourseListErrorMessage: "",
    studentCourseCount:null,
  },
  reducers: {
    studentCourseListClearState: (state) => {
      state.studentCourseListError = false;
      state.studentCourseListSuccess = false;
      state.studentCourseListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentCourseList.fulfilled]: (state, { payload }) => {
      state.studentCourseList = [];
      payload.data.results.forEach((item) => state.studentCourseList.push(item));
      state.studentCourseCount = payload.data.count;
      state.studentCourseListFetching = false;
      state.studentCourseListSuccess = true;
      return state;
    },
    [fetchStudentCourseList.rejected]: (state, action) => {
      state.studentCourseListFetching = false;
      state.studentCourseListError = true;
      state.studentCourseListErrorMessage = action?.payload;
    },
    [fetchStudentCourseList.pending]: (state) => {
      state.studentCourseListFetching = true;
    },
  },
});

export const { studentCourseListClearState } = studentCourseListSlice.actions;

export default studentCourseListSlice.reducer;
