import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteCoursePackage = createAsyncThunk(
  "admin/delete-course-package",
  async ({id }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.coursePackageDetailsURL.replace("{id}", id),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.msg) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);

const coursePackageDeleteSlice = createSlice({
  name: "delete-coursePackage",
  initialState: {
    dvlFetching: false,
    dvlSuccess: false,
    dvlError: false,
    dvlErrorMessage: "",
  },
  reducers: {
    dvlClearState: (state) => {
      state.dvlError = false;
      state.dvlSuccess = false;
      state.dvlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteCoursePackage.fulfilled]: (state, { payload }) => {
      state.dvlFetching = false;
      state.dvlSuccess = true;
      return state;
    },
    [deleteCoursePackage.rejected]: (state, action) => {
      state.dvlFetching = false;
      state.dvlError = true;
      state.dvlErrorMessage = action?.payload
    },
    [deleteCoursePackage.pending]: (state) => {
      state.dvlFetching = true;
    },
  },
});

export const { dvlClearState } = coursePackageDeleteSlice.actions;
export default coursePackageDeleteSlice.reducer;
