import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteBatch = createAsyncThunk(
  "admin/batch-delete",
  async ({ batchID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.batchSettingsDetailsURL.replace("{id}", batchID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const batchDeleteSlice = createSlice({
  name: "batch-delete",
  initialState: {
    batchDeleteFetching: false,
    batchDeleteSuccess: false,
    batchDeleteError: false,
    batchDeleteErrorMessage: "",
  },
  reducers: {
    batchDeleteClearState: (state) => {
      state.batchDeleteError = false;
      state.batchDeleteSuccess = false;
      state.batchDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteBatch.fulfilled]: (state, { payload }) => {
      state.batchDeleteFetching = false;
      state.batchDeleteSuccess = true;
      return state;
    },
    [deleteBatch.rejected]: (state, { payload }) => {
      state.batchDeleteFetching = false;
      state.batchDeleteError = true;
      state.batchDeleteErrorMessage = payload?.message;
    },
    [deleteBatch.pending]: (state) => {
      state.batchDeleteFetching = true;
    },
  },
});

export const { batchDeleteClearState } = batchDeleteSlice.actions;

export default batchDeleteSlice.reducer;
