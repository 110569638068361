import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const studentDeleteLeave = createAsyncThunk(
  "admin/delete-student-leave",
  async ({ leaveID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentAttendanceDeleteURL.replace("{id}", leaveID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const studentLeaveDeleteSlice = createSlice({
  name: "delete-student-leave",
  initialState: {
    studentLeaveDeleteFetching: false,
    studentLeaveDeleteSuccess: false,
    studentLeaveDeleteError: false,
    studentLeaveDeleteErrorMessage: "",
  },
  reducers: {
    studentLeaveDeleteClearState: (state) => {
      state.studentLeaveDeleteError = false;
      state.studentLeaveDeleteSuccess = false;
      state.studentLeaveDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [studentDeleteLeave.fulfilled]: (state, { payload }) => {
      state.studentLeaveDeleteFetching = false;
      state.studentLeaveDeleteSuccess = true;
      return state;
    },
    [studentDeleteLeave.rejected]: (state, action) => {
      state.studentLeaveDeleteFetching = false;
      state.studentLeaveDeleteError = true;
      state.studentLeaveDeleteErrorMessage = action?.payload;
    },
    [studentDeleteLeave.pending]: (state) => {
      state.studentLeaveDeleteFetching = true;
    },
  },
});

export const { studentLeaveDeleteClearState } = studentLeaveDeleteSlice.actions;

export default studentLeaveDeleteSlice.reducer;
