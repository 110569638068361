import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editClassSettingDetails = createAsyncThunk(
  "admin/class-setting-edit",
  async ({ classID, payload }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.classSettingDetailURL.replace("{id}", classID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const classSettingEditSlice = createSlice({
  name: "class-setting-edit",
  initialState: {

    classSettingEditFetching: false,
    classSettingEditSuccess: false,
    classSettingEditError: false,
    classSettingEditErrorMessage: "",
  },
  reducers: {
    classSettingEditClearState: (state) => {
      state.classSettingEditError = false;
      state.classSettingEditSuccess = false;
      state.classSettingEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editClassSettingDetails.fulfilled]: (state, { payload }) => {
      state.classSettingEditFetching = false;
      state.classSettingEditSuccess = true;
      return state;
    },
    [editClassSettingDetails.rejected]: (state, { payload }) => {
      state.classSettingEditFetching = false;
      state.classSettingEditError = true;
      state.classSettingEditErrorMessage = payload?.message;
    },
    [editClassSettingDetails.pending]: (state) => {
      state.classSettingEditFetching = true;
    },
  },
});

export const { classSettingEditClearState } = classSettingEditSlice.actions;

export default classSettingEditSlice.reducer;
