import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchDueDetails = createAsyncThunk(
  "admin/due-details",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.dueAmountURL,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const dueDetailsSlice = createSlice({
  name: "due-details",
  initialState: {
    dueDetails: {},
    dueDetailsFetching: false,
    dueDetailsSuccess: false,
    dueDetailsError: false,
    dueDetailsErrorMessage: "",
  },
  reducers: {
    clearDueDetailsState: (state) => {
      state.dueDetailsError = false;
      state.dueDetailsSuccess = false;
      state.dueDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchDueDetails.fulfilled]: (state, { payload }) => {
      state.dueDetails = payload.data;

      state.dueDetailsFetching = false;
      state.dueDetailsSuccess = true;
      return state;
    },
    [fetchDueDetails.rejected]: (state, action) => {
      state.dueDetailsFetching = false;
      state.dueDetailsError = true;
      state.dueDetailsErrorMessage = action?.payload;
    },
    [fetchDueDetails.pending]: (state) => {
      state.dueDetailsFetching = true;
    },
  },
});
export const { clearDueDetailsState } = dueDetailsSlice.actions;

export default dueDetailsSlice.reducer;
