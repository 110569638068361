import React, { memo, lazy, Suspense } from "react";

//router
import { Switch, Redirect } from "react-router";

import Loader from "../components/Loader";
//layoutpages
import Default from "../layouts/dashboard/default";
import Simple from "../layouts/dashboard/simple";
import Authmiddleware from "../router/Authmiddleware";

import ScrollTop from "../components/ScrollTop";

const userRoutes = [
  { path: "/dashboard", component: Default },
  { path: "/", component: () => <Redirect to="/dashboard" /> },
  { path: "/general-documents", component: Default },
  { path: "/student/:active_tab?", component: Default },
  { path: "/student/student-list/add-student", component: Default },
  { path: "/student/student-list/edit-student/:id", component: Default },
  { path: "/student/student-list/:studentID/:active_tab?", component: Default },
  { path: "/hr-management/:active_tab?", component: Default },

  //Cashbook
  { path: "/cashbook/income", component: Default },
  { path: "/cashbook/expenses", component: Default },
  { path: "/cashbook/chequebook", component: Default },
  { path: "/cashbook/transfers", component: Default },

  { path: "/settings/:active_tab?", component: Default },

  { path: "/report/finance", component: Default },
  { path: "/report/attendance", component: Default },
  { path: "/report/teacher", component: Default },
  { path: "/report/student", component: Default },
  { path: "/report-teacher/:id/:active_tab?", component: Default },
  { path: "/report-student/:id/:active_tab?", component: Default },

  // mentor
  { path: "/mentor", component: Default },
  { path: "/mentor/info", component: Default },
  { path: "/mentor/:id/:active_tab?", component: Default },

  { path: "/assets", component: Default },
  { path: "/leads", component: Default },
  { path: "/staff-profile", component: Default },
  { path: "/subscriptions", component: Default },
  { path: "/payment-history", component: Default },
  // teacher
  { path: "/teacher", component: Default },
  { path: "/teacher/:id/:active_tab?", component: Default },
  //exam mark
  { path: "/exam-mark", component: Default },
  {
    path: "/errors/error404",
    component: Simple,
  },
];

const authRoutes = [
  { path: "/auth", component: Simple },
  { path: "/index", component: Simple },
  { path: "/privacy-policy", component: Simple },
];

const IndexRouters = memo(() => {
  return (
    <ScrollTop>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </ScrollTop>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
