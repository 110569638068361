import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const childDocumentDelete = createAsyncThunk(
  "admin/child-document-delete",
  async ({ fileID ,stdID }, { rejectWithValue }) => {

    try {
      const response = await api.actionHandler({
        url: api.studentDocumentDetailURL.replace("{id}", fileID).replace("{std_id}", stdID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const childDocumentDeleteSlice = createSlice({
  name: "child-document-delete",
  initialState: {
    dcdsFetching: false,
    dcdsSuccess: false,
    dcdsError: false,
    dcdsErrorMessage: "",
  },
  reducers: {
    clearDcdsState: (state) => {
      state.dcdsError = false;
      state.dcdsSuccess = false;
      state.dcdsFetching = false;

      state.dcdsErrorMessage = "";

      return state;
    },
  },
  extraReducers: {
    [childDocumentDelete.fulfilled]: (state, { payload }) => {
      state.dcdsFetching = false;
      state.dcdsSuccess = true;
      return state;
    },
    [childDocumentDelete.rejected]: (state, action) => {
      state.dcdsFetching = false;
      state.dcdsError = true;
      state.dcdsErrorMessage = action?.payload;
    },
    [childDocumentDelete.pending]: (state) => {
      state.dcdsFetching = true;
    },
  },
});

export const { clearDcdsState } = childDocumentDeleteSlice.actions;

export default childDocumentDeleteSlice.reducer;
