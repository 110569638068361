import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";
export const deleteFees = createAsyncThunk(
  "admin/delete-fees",
  async ({ feesID ,studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentFeeDetailURL.replace("{student_id}",studentID).replace("{id}", feesID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const feesDeleteSlice = createSlice({
  name: "delete-fees",
  initialState: {
    deleteFeeFetching: false,
    deleteFeeSuccess: false,
    deleteFeeError: false,
    deleteFeeErrorMessage: "",
  },
  reducers: {
    deleteFeeClearState: (state) => {
      state.deleteFeeError = false;
      state.deleteFeeSuccess = false;
      state.deleteFeeFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteFees.fulfilled]: (state, { payload }) => {
      state.deleteFeeFetching = false;
      state.deleteFeeSuccess = true;
      return state;
    },
    [deleteFees.rejected]: (state, action) => {
      state.deleteFeeFetching = false;
      state.deleteFeeError = true;
      state.deleteFeeErrorMessage = action?.payload;
    },
    [deleteFees.pending]: (state) => {
      state.deleteFeeFetching = true;
    },
  },
});

export const { deleteFeeClearState } = feesDeleteSlice.actions;

export default feesDeleteSlice.reducer;
