import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchPackageList = createAsyncThunk(
  "admin/student-package-lists",
  async ({ limit, offset,studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentCourseURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{id}", studentID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const packageListSlice = createSlice({
  name: "student-course-lists",
  initialState: {
    packageList: [],
    packageFetching: false,
    packageSuccess: false,
    packageError: false,
    packageErrorMessage: "",
    childCount: null,
  },
  reducers: {
    packageClearState: (state) => {
      state.packageError = false;
      state.packageSuccess = false;
      state.packageFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchPackageList.fulfilled]: (state, { payload }) => {
      state.packageList = [];
      payload.data.results.forEach((item) => state.packageList.push(item));
      state.childCount = payload.data.count;
      state.packageFetching = false;
      state.packageSuccess = true;
      return state;
    },
    [fetchPackageList.rejected]: (state, action) => {
      state.packageFetching = false;
      state.packageError = true;
      state.packageErrorMessage = action?.payload;
    },
    [fetchPackageList.pending]: (state) => {
      state.packageFetching = true;
    },
  },
});

export const { packageClearState } = packageListSlice.actions;

export default packageListSlice.reducer;
