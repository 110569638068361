import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editBatch = createAsyncThunk(
  "admin/batch-edit",
  async ({ batchID, payload }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.batchSettingsDetailsURL.replace("{id}", batchID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const batchEditSlice = createSlice({
  name: "batch-edit",
  initialState: {

    batchEditFetching: false,
    batchEditSuccess: false,
    batchEditError: false,
    batchEditErrorMessage: "",
  },
  reducers: {
    batchEditClearState: (state) => {
      state.batchEditError = false;
      state.batchEditSuccess = false;
      state.batchEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editBatch.fulfilled]: (state, { payload }) => {
      state.batchEditFetching = false;
      state.batchEditSuccess = true;
      return state;
    },
    [editBatch.rejected]: (state, { payload }) => {
      state.batchEditFetching = false;
      state.batchEditError = true;
      state.batchEditErrorMessage = payload?.message;
    },
    [editBatch.pending]: (state) => {
      state.batchEditFetching = true;
    },
  },
});

export const { batchEditClearState } = batchEditSlice.actions;

export default batchEditSlice.reducer;
