import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchStaffAttendanceReport = createAsyncThunk(
  "admin/staff-attendance-report-list",
  async ({ month, year ,limit ,offset }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:
          (month === null || year === null)
            ? api.staffAttendanceReportListURL.replace("&month={month}", "").replace("&year={year}", "").replace("{limit}", limit).replace("{offset}", offset)
            : api.staffAttendanceReportListURL.replace("{month}", month).replace("{year}", year).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const staffAttendanceReportListSlice = createSlice({
  name: "staff-attendance-report-list",
  initialState: {
    staffAttendanceReportList: [],
    staffAttendanceReportFetching: false,
    staffAttendanceReportSuccess: false,
    staffAttendanceReportError: false,
    staffAttendanceReportErrorMessage: "",
    staffReportCount:null
  },
  reducers: {
    staffAttendanceReportClearState: (state) => {
      state.staffAttendanceReportError = false;
      state.staffAttendanceReportSuccess = false;
      state.staffAttendanceReportFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStaffAttendanceReport.fulfilled]: (state, { payload }) => {
      state.staffAttendanceReportList = [];
      payload.data.results.forEach((item) => state.staffAttendanceReportList.push(item));
      state.staffReportCount = payload.data.count;
      state.staffAttendanceReportFetching = false;
      state.staffAttendanceReportSuccess = true;
      return state;
    },
    [fetchStaffAttendanceReport.rejected]: (state, action) => {
      state.staffAttendanceReportFetching = false;
      state.staffAttendanceReportError = true;
      state.staffAttendanceReportErrorMessage = action?.payload;
    },
    [fetchStaffAttendanceReport.pending]: (state) => {
      state.staffAttendanceReportFetching = true;
    },
  },
});

export const { staffAttendanceReportClearState } = staffAttendanceReportListSlice.actions;

export default staffAttendanceReportListSlice.reducer;
