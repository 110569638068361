import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchSalaryDetails = createAsyncThunk(
  "admin/salary-details",
  async ({ salaryID }, { rejectWithValue }) => {
    try {
      console.log({salaryID});
      const response = await api.actionHandler({
        url: api.salaryDetailsURL.replace("{id}", salaryID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const salaryDetailsSlice = createSlice({
  name: "salary-details",
  initialState: {
    salaryDetails: {},
    esdFetching: false,
    esdSuccess: false,
    esdError: false,
    esdErrorMessage: "",
  },
  reducers: {
    clearEsdState: (state) => {
      state.esdError = false;
      state.esdSuccess = false;
      state.esdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSalaryDetails.fulfilled]: (state, { payload }) => {
      state.salaryDetails = payload.data;

      state.esdFetching = false;
      state.esdSuccess = true;
      return state;
    },
    [fetchSalaryDetails.rejected]: (state, action) => {
      state.esdFetching = false;
      state.esdError = true;
      state.esdErrorMessage = action?.payload;
    },
    [fetchSalaryDetails.pending]: (state) => {
      state.esdFetching = true;
    },
  },
});
export const { clearEsdState } = salaryDetailsSlice.actions;

export default salaryDetailsSlice.reducer;
