import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchSalaryList = createAsyncThunk(
  "admin/salary-list",
  async ({ month, year, limit, offset }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:
          year === null || month === null
            ? api.salaryListURL
                .replace("&month={month}", "")
                .replace("&year={year}", "")
                .replace("{offset}", offset)
                .replace("{limit}", limit)
            : api.salaryListURL
                .replace("{month}", month)
                .replace("{year}", year)
                .replace("{offset}", offset)
                .replace("{limit}", limit),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const salaryListSlice = createSlice({
  name: "salary-list",
  initialState: {
    salaryList: [],
    eslFetching: false,
    eslSuccess: false,
    eslError: false,
    eslErrorMessage: "",
    payrollCount: null,
  },
  reducers: {
    eslClearState: (state) => {
      state.eslError = false;
      state.eslSuccess = false;
      state.eslFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSalaryList.fulfilled]: (state, { payload }) => {
      state.salaryList = [];
      payload.data.results.forEach((item) => state.salaryList.push(item));
      state.payrollCount = payload.data.count;
      state.eslFetching = false;
      state.eslSuccess = true;
      return state;
    },
    [fetchSalaryList.rejected]: (state, action) => {
      state.eslFetching = false;
      state.eslError = true;
      state.eslErrorMessage = action?.payload;
    },
    [fetchSalaryList.pending]: (state) => {
      state.eslFetching = true;
    },
  },
});

export const { eslClearState } = salaryListSlice.actions;

export default salaryListSlice.reducer;
