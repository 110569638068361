import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchCourseDetails = createAsyncThunk(
  "admin/course-details",
  async ({ courseID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.courseSettingsDetailsURL.replace("{id}", courseID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const courseDetailsSlice = createSlice({
  name: "course-details",
  initialState: {
    courseDetails: {},
    courseDetailsFetching: false,
    courseDetailsSuccess: false,
    courseDetailsError: false,
    courseDetailsErrorMessage: "",
  },
  reducers: {
    courseDetailsClearState: (state) => {
      state.courseDetailsError = false;
      state.courseDetailsSuccess = false;
      state.courseDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCourseDetails.fulfilled]: (state, { payload }) => {
      state.courseDetails = payload.data;
      state.courseDetailsFetching = false;
      state.courseDetailsSuccess = true;
      return state;
    },
    [fetchCourseDetails.rejected]: (state, { payload }) => {
      state.courseDetailsFetching = false;
      state.courseDetailsError = true;
      state.courseDetailsErrorMessage = payload?.message;
    },
    [fetchCourseDetails.pending]: (state) => {
      state.courseDetailsFetching = true;
    },
  },
});

export const { courseDetailsClearState } = courseDetailsSlice.actions;

export default courseDetailsSlice.reducer;
