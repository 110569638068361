import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAccountReport = createAsyncThunk(
  "admin/report-list",
  async ({ uptoDate }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:(uptoDate === "")? api.accountReportListURL.replace("?upto_date={upto_date}", ""): api.accountReportListURL.replace("{upto_date}", uptoDate),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const accountReportListSlice = createSlice({
  name: "report-list",
  initialState: {
    accountReport: {},
    rplFetching: false,
    rplSuccess: false,
    rplError: false,
    rplErrorMessage: "",
  },
  reducers: {
    rplClearState: (state) => {
      state.rplError = false;
      state.rplSuccess = false;
      state.rplFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAccountReport.fulfilled]: (state, { payload }) => {
      state.accountReport = payload.data;
      state.rplFetching = false;
      state.rplSuccess = true;
      return state;
    },
    [fetchAccountReport.rejected]: (state, action) => {
      state.rplFetching = false;
      state.rplError = true;
      state.rplErrorMessage = action?.payload;
    },
    [fetchAccountReport.pending]: (state) => {
      state.rplFetching = true;
    },
  },
});

export const { rplClearState } = accountReportListSlice.actions;

export default accountReportListSlice.reducer;
