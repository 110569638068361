import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";
export const editFeesDetails = createAsyncThunk(
  "admin/fees-edit",
  async ({payload, feesID ,studentID}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentFeeDetailURL.replace("{student_id}",studentID).replace("{id}", feesID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const feesEditSlice = createSlice({
  name: "fees-edit",
  initialState: {
    editFeesFetching: false,
    editFeesSuccess: false,
    editFeesError: false,
    editFeesErrorMessage: "",
  },
  reducers: {
    clearEditFeesState: (state) => {
      state.editFeesError = false;
      state.editFeesSuccess = false;
      state.editFeesFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editFeesDetails.fulfilled]: (state, { payload }) => {
      state.editFeesFetching = false;
      state.editFeesSuccess = true;
      return state;
    },
    [editFeesDetails.rejected]: (state, action) => {
      state.editFeesFetching = false;
      state.editFeesError = true;
      state.editFeesErrorMessage = action?.payload;
    },
    [editFeesDetails.pending]: (state) => {
      state.editFeesFetching = true;
    },
  },
});
export const { clearEditFeesState } = feesEditSlice.actions;

export default feesEditSlice.reducer;
