import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";
import financialYearListSlice from "./misc/financialYearSlice";

//Login
import userSlice from "./login/loginSlice";
import userProfileSlice from "./profile/userProfileSlice";
import existenceCheckSlice from "./login/existenceCheckSlice";

import dashboardDataSlice from "./dashboard/dashboardDataSlice";
import dashboardReportSlice from "./dashboard/dashboardReportSlice";
import dashboardIncomeSlice from "./dashboard/dashboardIncomeSlice";

//Students
import upcomingClassListSlice from "./students/upcomingClassListSlice";
import childListSlice from "./students/childListSlice";
import basicDetailsSlice from "./students/basicDetailsSlice";
import editBasicDetailsSlice from "./students/editBasicDetailsSlice";
import attendanceListSlice from "./students/attendanceListSlice";
import createAdmissionDetailsSlice from "./students/createAdmissionDetailsSlice";
import deleteChildrenSlice from "./students/deleteChildrenSlice";
import markAbsentSlice from "./students/markAbsentSlice";
import studentDocumentCreateSlice from "./students/studentDocumentCreateSlice";
import studentDocumentListSlice from "./students/studentDocumentListSlice";
import childDocumentDeleteSlice from "./students/childDocumentDeleteSlice";
import studentLeaveDeleteSlice from "./students/studentLeaveDeleteSlice";
import excelUploadSlice from "./students/excelUploadSlice";
import studentSubjectListSlice from "./students/studentSubjectListSlice";
import studentTransListSlice from "./students/transacions/studentTransListSlice";
import studentTransCreateSlice from "./students/transacions/studentTransCreateSlice";
import studentTransDetailsSlice from "./students/transacions/studentTransDetailsSlice";
import studentTransEditSlice from "./students/transacions/studentTransEditSlice";
import studentTransDeleteSlice from "./students/transacions/studentTransDeleteSlice";

//Fees
import feesListSlice from "./students/fees/feesListSlice";
import feesCreateSlice from "./students/fees/feesCreateSlice";
import feesDeleteSlice from "./students/fees/feesDeleteSlice";
import feesDetailsSlice from "./students/fees/feesDetailsSlice";
import feesEditSlice from "./students/fees/feesEditSlice";
import subjectFeesListSlice from "./subjectFees/subjectFeesListSlice";
import subjectFeesDetailsSlice from "./subjectFees/subjectFeesDetailsSlice";
import subjectFeesEditSlice from "./subjectFees/subjectFeesEditSlice";
import subjectFeesDeleteSlice from "./subjectFees/subjectFeesDeleteSlice";

//Courses
import studentCourseCreateSlice from "./students/studentCourses/studentCourseCreateSlice";
import studentCourseListSlice from "./students/studentCourses/studentCourseListSlice";
import studentCourseDetailsSlice from "./students/studentCourses/studentCourseDetailsSlice";
import studentCourseEditSlice from "./students/studentCourses/studentCourseEditSlice";
import studentCourseDeleteSlice from "./students/studentCourses/studentCourseDeleteSlice";
import studentPendingFeesSlice from "./students/studentPendingFeesSlice";

//Classes
import studentClassCreateSlice from "./students/studentClasses/studentClassCreateSlice";
import studentClassListSlice from "./students/studentClasses/studentClassListSlice";
import studentClassDetailsSlice from "./students/studentClasses/studentClassDetailsSlice";
import studentClassEditSlice from "./students/studentClasses/studentClassEditSlice";
import studentClassDeleteSlice from "./students/studentClasses/studentClassDeleteSlice";
import upComingListSlice from "./students/upComingListSlice";
import packageListSlice from "./students/package/packageListSlice";
import packageCreateSlice from "./students/package/packageCreateSlice";

//Academies
import academyListSlice from "./academies/academyListSlice";
import academyEditSlice from "./academies/academyEditSlice";
import selectedAcademySlice from "./academies/selectedAcademySlice";

//Assets
import assetListSlice from "./assets/assetsListSlice";
import assetEditSlice from "./assets/assetEditSlice";
import assetAddSlice from "./assets/assetAddSlice";
import assetDetailsSlice from "./assets/assetDetails";
import assetDeleteSlice from "./assets/assetDeleteSlice";

//Leads
import leadListSlice from "./leads/leadListSlice";
import leadCreateSlice from "./leads/leadCreateSlice";
import leadDetailsSlice from "./leads/leadDetailsSlice";
import leadEditSlice from "./leads/leadEditSlice";
import leadDeleteSlice from "./leads/leadDeleteSlice";

//HR
import staffDeleteSlice from "./usermanagement/staff/staffDeleteSlice";
import staffDetailsSlice from "./usermanagement/staff/staffDetailsSlice";
import staffEditSlice from "./usermanagement/staff/staffEditSlice";
import staffAddSlice from "./usermanagement/staff/staffAddSlice";

//Leave
import userAttendanceListSlice from "./usermanagement/attendance/userAttendanceListSlice";
import leaveRequestAddSlice from "./usermanagement/attendance/leaveRequestAddSlice";
import leaveDeleteSlice from "./usermanagement/attendance/leaveDeleteSlice";
import attendanceDetailsSlice from "./usermanagement/attendance/attendanceDetailsSlice";

//Settings
//Bank
import bankAccountListSlice from "./demoSettings/banks/bankAccountListSlice";
import addBankAccountSlice from "./demoSettings/banks/bankAccountAddSlice";
import bankAccountEditSlice from "./demoSettings/banks/bankAccountEditSlice";
import bankAccountDetailsSlice from "./demoSettings/banks/bankAccountDetailsSlice";
import deleteBankAccountSlice from "./demoSettings/banks/bankAccountDeleteSlice";

//Business Details
import businessDetailsSlice from "./demoSettings/business/businessDetailsListSlice";
import businessEditSlice from "./demoSettings/business/businessDetailsEditSlice";
import createBusinesDetailsSlice from "./demoSettings/business/createBusinesDetailsSlice";

//Course
import courseListSlice from "./demoSettings/course/courseListSlice";
import courseCreateSlice from "./demoSettings/course/courseCreateSlice";
import courseEditSlice from "./demoSettings/course/courseEditSlice";
import courseDetailsSlice from "./demoSettings/course/courseDetailsSlice";
import courseDeleteSlice from "./demoSettings/course/courseDeleteSlice";

//Class Setting
import classSettingListSlice from "./demoSettings/classes/classSettingListSlice";
import classSettingCreateSlice from "./demoSettings/classes/classSettingCreateSlice";
import classSettingDetailsSlice from "./demoSettings/classes/classSettingDetailsSlice";
import classSettingEditSlice from "./demoSettings/classes/classSettingEditSlice";
import classSettingDeleteSlice from "./demoSettings/classes/classSettingDeleteSlice";

//Batch
import batchListSlice from "./demoSettings/batch/batchListSlice";
import batchCreateSlice from "./demoSettings/batch/batchCreateSlice";
import batchDetailsSlice from "./demoSettings/batch/batchDetailsSlice";
import batchEditSlice from "./demoSettings/batch/batchEditSlice";
import batchDeleteSlice from "./demoSettings/batch/batchDeleteSlice";

//Payroll
import salaryListSlice from "./usermanagement/payroll/employeeSalary/salaryListSlice";
import salaryAddSlice from "./usermanagement/payroll/employeeSalary/salaryAddSlice";
import salaryDetailsSlice from "./usermanagement/payroll/employeeSalary/salaryDetailsSlice";
import salaryEditSlice from "./usermanagement/payroll/employeeSalary/salaryEditSlice";
import SalaryDeleteSlice from "./usermanagement/payroll/employeeSalary/SalaryDeleteSlice";
import teacherPayrollListSlice from "./usermanagement/payroll/teacherPayroll/teacherPayrollListSlice";
import teacherPayrollAddSlice from "./usermanagement/payroll/teacherPayroll/teacherPayrollAddSlice";
import teacherPayrollDetailsSlice from "./usermanagement/payroll/teacherPayroll/teacherPayrollDetailsSlice";
import teacherPayrollEditSlice from "./usermanagement/payroll/teacherPayroll/teacherPayrollEditSlice";
import teacherPayrollDeleteSlice from "./usermanagement/payroll/teacherPayroll/teacherPayrollDeleteSlice";
// coursePackage
import coursePackageListSlice from "./coursePackage/coursePackageListSlice";
import coursePackageCreateSlice from "./coursePackage/coursePackageCreateSlice";
import coursePackageDetailsSlice from "./coursePackage/coursePackageDetailsSlice";
import coursePackageEditSlice from "./coursePackage/coursePackageEditSlice";
import coursePackageDeleteSlice from "./coursePackage/coursePackageDeleteSlice";
//Categories & Sub Categories
import transCategoriesListSlice from "./demoSettings/transactionCategories/transCategoriesListSlice";
import transCategoriesAddSlice from "./demoSettings/transactionCategories/transCategoriesAddSlice";
import transCategoriesDetailsSlice from "./demoSettings/transactionCategories/transCategoriesDetailsSlice";
import transCategoriesEditSlice from "./demoSettings/transactionCategories/transCategoriesEditSlice";
import transCategoriesDeleteSlice from "./demoSettings/transactionCategories/transCategoriesDeleteSlice";

import transSubCategoriesListSlice from "./demoSettings/transactionSubCategories/transSubCategoriesListSlice";
import transSubCategoriesAddSlice from "./demoSettings/transactionSubCategories/transSubCategoriesAddSlice";
import transSubCategoriesDetailsSlice from "./demoSettings/transactionSubCategories/transSubCategoriesDetailsSlice";
import transSubCategoriesEditSlice from "./demoSettings/transactionSubCategories/transSubCategoriesEditSlice";
import transSubCategoriesDeleteSlice from "./demoSettings/transactionSubCategories/transSubCategoriesDeleteSlice";

//Accounts
//checkbook
import checkbookListSlice from "./accounting/checkbook/checkbookListSlice";
import checkbookCreateSlice from "./accounting/checkbook/checkbookCreateSlice";
import checkbookDetailsSlice from "./accounting/checkbook/checkbookDetailsSlice";
import checkbookEditSlice from "./accounting/checkbook/checkbookEditSlice";
import checkbookDeleteSlice from "./accounting/checkbook/checkbookDeleteSlice";

import transactionCreateSlice from "./accounting/transaction/transactionCreateSlice";
import transactionListSlice from "./accounting/transaction/transactionListSlice";
import transactionDetailsSlice from "./accounting/transaction/transactionDetailsSlice";
import transactionEditSlice from "./accounting/transaction/transactionEditSlice";
import transactionDeleteSlice from "./accounting/transaction/transactionDeleteSlice";

import transferCreateSlice from "./accounting/transfers/transferCreateSlice";
import transferListSlice from "./accounting/transfers/transferListSlice";
import transferDetailsSlice from "./accounting/transfers/transferDetailsSlice";
import transferEditSlice from "./accounting/transfers/transferEditSlice";
import transferDeleteSlice from "./accounting/transfers/transferDeleteSlice";

//Documents
import documentsListSlice from "./documents/documentsListSlice";
import documentsCreateSlice from "./documents/documentsCreateSlice";
import documentDeleteSlice from "./documents/documentDeleteSlice";

//Reports
import financeReportListSlice from "./reports/financeReportListSlice";
import studentAttendanceReportListSlice from "./reports/studentAttendanceReportListSlice";
import staffAttendanceReportListSlice from "./reports/staffAttendanceReportListSlice";

//Subscriptions
import subscriptionsListSlice from "./subscriptions/subscriptionsListSlice";
import extentPlanDetailsSlice from "./subscriptions/extentPlanDetailsSlice";
import extendPlanPostSlice from "./subscriptions/extendPlanPostSlice";
import transactionHistoryListSlice from "./subscriptions/transactionHistoryListSlice";
import switchSubscriptionPlanSlice from "./subscriptions/switchSubscriptionPlanSlice";
import dueDetailsSlice from "./subscriptions/dueDetailsSlice";
import paymentVerifySlice from "./subscriptions/paymentVerifySlice";
import bannerNotificationSlice from "./subscriptions/bannerNotificationSlice";

//Email verification
import emailVerifyBannerSlice from "./demoSettings/EmailVerification/emailVerifyBannerSlice";
import emailVerifyOtpSlice from "./demoSettings/EmailVerification/emailVerifyOtpSlice";
import emailOtpSendSlice from "./demoSettings/EmailVerification/emailOtpSendSlice";

//Custom Fields
import formDataListSlice from "./academies/FormData/formDataListSlice";
import createFormDataSlice from "./academies/FormData/createFormDataSlice";
import formDataDetailsSlice from "./academies/FormData/formDataDetailsSlice";
import formDataDeleteSlice from "./academies/FormData/formDataDeleteSlice";
import formDataEditSlice from "./academies/FormData/formDataEditSlice";

//Fees Categories
import feeCategoriesAddSlice from "./demoSettings/feesCategories/feeCategoriesAddSlice";
import feeCategoriesListSlice from "./demoSettings/feesCategories/feeCategoriesListSlice";
import feeCategoriesDetailsSlice from "./demoSettings/feesCategories/feeCategoriesDetailsSlice";
import feeCategoriesDeleteSlice from "./demoSettings/feesCategories/feeCategoriesDeleteSlice";
import feeCategoriesEditSlice from "./demoSettings/feesCategories/feeCategoriesEditSlice";

//Initial Fees
import initialFeeListSlice from "./demoSettings/InitialFees/initialFeeListSlice";
import initialFeeAddSlice from "./demoSettings/InitialFees/initialFeeAddSlice";
import initialFeeDetailsSlice from "./demoSettings/InitialFees/initialFeeDetailsSlice";
import initialFeeEditSlice from "./demoSettings/InitialFees/initialFeeEditSlice";
import initialFeeDeleteSlice from "./demoSettings/InitialFees/initialFeeDeleteSlice";

//Application Number
import applicationNumbersSlice from "./demoSettings/applicationNumber/applicationNumbersSlice";
import applicationNumberDetailsSlice from "./demoSettings/applicationNumber/applicationNumberDetailsSlice";
import applicationNumberEditSlice from "./demoSettings/applicationNumber/applicationNumberEditSlice";

//Account Report
import accountReportListSlice from "./reports/accountReportListSlice";
// mentor
import mentorListSlice from "./mentor/mentorListSlice";
import mentorCreateSlice from "./mentor/mentorCreateSlice";
import mentorDetailSlice from "./mentor/mentorDetailSlice";
import mentorEditSlice from "./mentor/mentorEditSlice";
import mentorDeleteSlice from "./mentor/mentorDeleteSlice";
import mentorStudentListSlice from "./mentor/mentorStudentListSlice";
import mentorUnAssignSlice from "./mentor/mentorUnAssignSlice";
import mentorStudentAssignSlice from "./mentor/mentorStudentAssignSlice";
import mentorStudentPendingFeesSlice from "./mentor/mentorStudentPendingFeesSlice";
import mentorInfoSlice from "./mentor/mentorInfoSlice";

// staff
import staffListSlice from "./Staff/staffListSlice";
// subject
import subjectListSlice from "./subject/subjectListSlice";
import subjectCreateSlice from "./subject/subjectCreateSlice";
import SubjectDeleteSlice from "./subject/SubjectDeleteSlice";
import subjectEditSlice from "./subject/subjectEditSlice";
import subjectDetailsSlice from "./subject/subjectDetailsSlice";

// teacher
import teacherLIstSlice from "./teacher/teacherLIstSlice";
import teacherCreateSlice from "./teacher/teacherCreateSlice";
import teacherDetailsSlice from "./teacher/teacherDetailsSlice";
import teacherEditSlice from "./teacher/teacherEditSlice";
import teacherDeleteSlice from "./teacher/teacherDeleteSlice";
import teacherClassHistorySlice from "./teacher/teacherClassHistorySlice";
import teacherPaymentHistorySlice from "./teacher/teacherPaymentHistorySlice";

// common
import countryListSlice from "./Others/countryListSlice";
import stateListSlice from "./Others/stateListSlice";
import districtListSlice from "./Others/districtListSlice";

// syllabus
import syllabusListSlice from "./syllabus/syllabusListSlice";
import syllabusCreateSlice from "./syllabus/syllabusCreateSlice";
import syllabusDetailsSlice from "./syllabus/syllabusDetailsSlice";
import syllabusEditSlice from "./syllabus/syllabusEditSlice";
import syllabusDeleteSlice from "./syllabus/syllabusDeleteSlice";

// teacherSubjectFees
import teacherSubjectFeesListSlice from "./TeacherSubjectFees/teacherSubjectFeesListSlice";
import teacherSubjectRateCreateSlice from "./TeacherSubjectFees/teacherSubjectRateCreateSlice";
import teacherSubjectEditSlice from "./TeacherSubjectFees/teacherSubjectEditSlice";
import teacherSubjectDetailsSlice from "./TeacherSubjectFees/teacherSubjectDetailsSlice";
import teacherSubjectRateDeleteSlice from "./TeacherSubjectFees/teacherSubjectRateDeleteSlice";

// exam mark
import examMarkListSlice from "./examMark/examMarkListSlice";
import examMarkCreateSlice from "./examMark/examMarkCreateSlice";
import examMarkDetailsSlice from "./examMark/examMarkDetailsSlice";
import examMarkEditSlice from "./examMark/examMarkEditSlice";
import examMarkDeleteSlice from "./examMark/examMarkDeleteSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
    userSlice,
    existenceCheckSlice,
    financialYearListSlice,
    dashboardReportSlice,
    dashboardIncomeSlice,
    dashboardDataSlice,
    userProfileSlice,
    //Staff Management
    staffListSlice,
    staffAddSlice,
    staffDetailsSlice,
    staffDeleteSlice,
    salaryListSlice,
    salaryAddSlice,
    salaryDetailsSlice,
    salaryEditSlice,
    SalaryDeleteSlice,
    staffEditSlice,
    userAttendanceListSlice,
    attendanceDetailsSlice,
    leaveDeleteSlice,
    leaveRequestAddSlice,
    //Students
    studentLeaveDeleteSlice,
    childListSlice,
    basicDetailsSlice,
    editBasicDetailsSlice,
    createAdmissionDetailsSlice,
    deleteChildrenSlice,
    attendanceListSlice,
    markAbsentSlice,
    studentDocumentCreateSlice,
    studentDocumentListSlice,
    childDocumentDeleteSlice,
    excelUploadSlice,
    //Assets
    assetListSlice,
    assetEditSlice,
    assetAddSlice,
    assetDetailsSlice,
    assetDeleteSlice,
    //Leads
    leadListSlice,
    leadCreateSlice,
    leadDetailsSlice,
    leadEditSlice,
    leadDeleteSlice,
    //Transactions Categories and Sub Categories
    transCategoriesListSlice,
    transCategoriesAddSlice,
    transCategoriesDetailsSlice,
    transCategoriesEditSlice,
    transCategoriesDeleteSlice,
    transSubCategoriesListSlice,
    transSubCategoriesAddSlice,
    transSubCategoriesDetailsSlice,
    transSubCategoriesEditSlice,
    transSubCategoriesDeleteSlice,
    //Transactions
    transactionCreateSlice,
    transactionListSlice,
    transactionDetailsSlice,
    transactionEditSlice,
    transactionDeleteSlice,
    //Cheque Book
    checkbookListSlice,
    checkbookCreateSlice,
    checkbookDetailsSlice,
    checkbookEditSlice,
    checkbookDeleteSlice,
    //Documents
    documentsListSlice,
    documentsCreateSlice,
    documentDeleteSlice,
    //Transfers
    transferCreateSlice,
    transferListSlice,
    transferDetailsSlice,
    transferEditSlice,
    transferDeleteSlice,
    //Settings
    createBusinesDetailsSlice,
    deleteBankAccountSlice,
    businessDetailsSlice,
    businessEditSlice,
    bankAccountListSlice,
    addBankAccountSlice,
    bankAccountEditSlice,
    bankAccountDetailsSlice,
    //Class Settings
    classSettingListSlice,
    classSettingCreateSlice,
    classSettingDetailsSlice,
    classSettingEditSlice,
    classSettingDeleteSlice,
    //Batch Settings
    batchListSlice,
    batchCreateSlice,
    batchDetailsSlice,
    batchEditSlice,
    batchDeleteSlice,
    //Course Settings
    courseListSlice,
    courseCreateSlice,
    courseEditSlice,
    courseDetailsSlice,
    courseDeleteSlice,
    //Student Fees
    feesListSlice,
    feesCreateSlice,
    feesDeleteSlice,
    feesDetailsSlice,
    feesEditSlice,
    //Student Course
    studentCourseCreateSlice,
    studentSubjectListSlice,
    studentCourseListSlice,
    studentCourseDetailsSlice,
    studentCourseEditSlice,
    studentCourseDeleteSlice,
    studentPendingFeesSlice,
    upcomingClassListSlice,
    //Student Transaction
    studentTransListSlice,
    studentTransCreateSlice,
    studentTransDetailsSlice,
    studentTransEditSlice,
    studentTransDeleteSlice,
    //Student Class
    studentClassCreateSlice,
    studentClassListSlice,
    studentClassDetailsSlice,
    studentClassEditSlice,
    studentClassDeleteSlice,
    upComingListSlice,
    packageListSlice,
    packageCreateSlice,
    //Academy and Subscriptions
    academyListSlice,
    academyEditSlice,
    selectedAcademySlice,
    subscriptionsListSlice,
    extentPlanDetailsSlice,
    extendPlanPostSlice,
    transactionHistoryListSlice,
    switchSubscriptionPlanSlice,
    dueDetailsSlice,
    paymentVerifySlice,
    //Email verification
    bannerNotificationSlice,
    emailVerifyBannerSlice,
    emailVerifyOtpSlice,
    emailOtpSendSlice,
    //Custom Fields
    formDataListSlice,
    createFormDataSlice,
    formDataDetailsSlice,
    formDataDeleteSlice,
    formDataEditSlice,
    //Reports
    studentAttendanceReportListSlice,
    staffAttendanceReportListSlice,
    financeReportListSlice,
    //Fees Categories
    feeCategoriesAddSlice,
    feeCategoriesListSlice,
    feeCategoriesDetailsSlice,
    feeCategoriesDeleteSlice,
    feeCategoriesEditSlice,
    //Initial Fees
    initialFeeListSlice,
    initialFeeAddSlice,
    initialFeeDetailsSlice,
    initialFeeEditSlice,
    initialFeeDeleteSlice,
    //Application Number
    applicationNumbersSlice,
    applicationNumberDetailsSlice,
    applicationNumberEditSlice,

    //Account Report
    accountReportListSlice,
    // mentor
    mentorListSlice,
    mentorCreateSlice,
    mentorEditSlice,
    mentorDetailSlice,
    mentorDeleteSlice,
    mentorStudentListSlice,
    mentorUnAssignSlice,
    mentorStudentAssignSlice,
    mentorStudentPendingFeesSlice,
    mentorInfoSlice,
    // subject
    subjectListSlice,
    subjectCreateSlice,
    SubjectDeleteSlice,
    subjectEditSlice,
    subjectDetailsSlice,

    //teacher
    teacherLIstSlice,
    teacherCreateSlice,
    teacherDetailsSlice,
    teacherEditSlice,
    teacherDeleteSlice,
    teacherClassHistorySlice,
    teacherPaymentHistorySlice,
    // common
    countryListSlice,
    districtListSlice,
    stateListSlice,
    // syllabus
    syllabusListSlice,
    syllabusCreateSlice,
    syllabusDetailsSlice,
    syllabusEditSlice,
    syllabusDeleteSlice,
    // teacher
    subjectFeesListSlice,
    subjectFeesEditSlice,
    subjectFeesDeleteSlice,
    subjectFeesDetailsSlice,
    // teacherSubjectFees
    teacherSubjectFeesListSlice,
    teacherSubjectRateCreateSlice,
    teacherSubjectEditSlice,
    teacherSubjectDetailsSlice,
    teacherSubjectRateDeleteSlice,
    // exam mark
    examMarkListSlice,
    examMarkCreateSlice,
    examMarkDetailsSlice,
    examMarkEditSlice,
    examMarkDeleteSlice,
    // teacherPayroll
    teacherPayrollListSlice,
    teacherPayrollAddSlice,
    teacherPayrollDetailsSlice,
    teacherPayrollEditSlice,
    teacherPayrollDeleteSlice,
    //coursePackge
    coursePackageListSlice,
    coursePackageCreateSlice,
    coursePackageDetailsSlice,
    coursePackageEditSlice,
    coursePackageDeleteSlice,
  },
});
