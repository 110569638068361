import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchDistrictList = createAsyncThunk(
  "/common/district/",
  async ({ state }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.districtListURL.replace("{state}", state),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const districtListSlice = createSlice({
  name: "district-list",
  initialState: {
    districtList: [],
    districtCount: null,
    districtFetching: false,
    districtSuccess: false,
    districtError: false,
    districtErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.districtError = false;
      state.districtSuccess = false;
      state.districtFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchDistrictList.fulfilled]: (state, { payload }) => {
      state.districtList = [];
      payload.data.results.forEach((item) => state.districtList.push(item));
      state.districtCount = payload.data.count;

      state.districtFetching = false;
      state.districtSuccess = true;
      return state;
    },
    [fetchDistrictList.rejected]: (state, action) => {
      state.districtFetching = false;
      state.districtError = true;
      state.districtErrorMessage = action?.payload;
    },
    [fetchDistrictList.pending]: (state) => {
      state.districtFetching = true;
    },
  },
});

export const { fclClearState } = districtListSlice.actions;

export default districtListSlice.reducer;
