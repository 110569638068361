import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteStudentCourse = createAsyncThunk(
  "admin/delete-studentCourse",
  async ({ courseID ,studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:api.studentCourseDetailURL.replace("{student_id}",studentID).replace("{id}", courseID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const studentCourseDeleteSlice = createSlice({
  name: "delete-studentCourse",
  initialState: {
    studentCourseDeleteFetching: false,
    studentCourseDeleteSuccess: false,
    studentCourseDeleteError: false,
    studentCourseDeleteErrorMessage: "",
  },
  reducers: {
    studentCourseDeleteClearState: (state) => {
      state.studentCourseDeleteError = false;
      state.studentCourseDeleteSuccess = false;
      state.studentCourseDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteStudentCourse.fulfilled]: (state, { payload }) => {
      state.studentCourseDeleteFetching = false;
      state.studentCourseDeleteSuccess = true;
      return state;
    },
    [deleteStudentCourse.rejected]: (state, action) => {
      state.studentCourseDeleteFetching = false;
      state.studentCourseDeleteError = true;
      state.studentCourseDeleteErrorMessage = action?.payload;
    },
    [deleteStudentCourse.pending]: (state) => {
      state.studentCourseDeleteFetching = true;
    },
  },
});

export const { studentCourseDeleteClearState } = studentCourseDeleteSlice.actions;

export default studentCourseDeleteSlice.reducer;
