import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetcHApplicationNumber = createAsyncThunk(
  "admin/settings/application-numbers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.applicationNumberListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const applicationNumbersSlice = createSlice({
  name: "application-numbers",
  initialState: {
    applicationNumber: null,
    applicationNumberFetching: false,
    applicationNumberSuccess: false,
    applicationNumberError: false,
    applicationNumberErrorMessage: "",
  },
  reducers: {
    applicationNumberClearState: (state) => {
      state.applicationNumberError = false;
      state.applicationNumberSuccess = false;
      state.applicationNumberFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetcHApplicationNumber.fulfilled]: (state, { payload }) => {
      state.applicationNumber = payload.result.application_number;
      state.applicationNumberFetching = false;
      state.applicationNumberSuccess = true;
      return state;
    },
    [fetcHApplicationNumber.rejected]: (state, action) => {
      state.applicationNumberFetching = false;
      state.applicationNumberError = true;
      state.applicationNumberErrorMessage = action?.payload;
    },
    [fetcHApplicationNumber.pending]: (state) => {
      state.applicationNumberFetching = true;
    },
  },
});

export const { applicationNumberClearState } = applicationNumbersSlice.actions;

export default applicationNumbersSlice.reducer;
