import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const createStudentCourse = createAsyncThunk(
  "admin/create-student-course",
  async ({ payload, studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentCoursePostURL.replace("{id}", studentID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const studentCourseCreateSlice = createSlice({
  name: "create-student-course",
  initialState: {
    studentCourseCreateFetching: false,
    studentCourseCreateSuccess: false,
    studentCourseCreateError: false,
    studentCourseCreateErrorMessage: "",
  },
  reducers: {
    clearStudentCourseCreateState: (state) => {
      state.studentCourseCreateError = false;
      state.studentCourseCreateSuccess = false;
      state.studentCourseCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createStudentCourse.fulfilled]: (state, { payload }) => {
      state.studentCourseCreateFetching = false;
      state.studentCourseCreateSuccess = true;
      return state;
    },
    [createStudentCourse.rejected]: (state, action) => {
      state.studentCourseCreateFetching = false;
      state.studentCourseCreateError = true;
      state.studentCourseCreateErrorMessage = action?.payload;
    },
    [createStudentCourse.pending]: (state) => {
      state.studentCourseCreateFetching = true;
    },
  },
});

export const { clearStudentCourseCreateState } = studentCourseCreateSlice.actions;

export default studentCourseCreateSlice.reducer;
