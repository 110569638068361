import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchCoursePackageList = createAsyncThunk(
  "/admin/course-package/",
  async ({ limit, offset }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.coursePackageListURL
          .replace("{offset}", offset)
          .replace("{limit}", limit),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const coursePackageListSlice = createSlice({
  name: "coursePackage-list",
  initialState: {
    coursePackageList: [],
    childCount: null,
    courseFetching: false,
    courseSuccess: false,
    courseError: false,
    courseErrorMessage: "",
  },
  reducers: {
    courseClearState: (state) => {
      state.courseError = false;
      state.courseSuccess = false;
      state.courseFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCoursePackageList.fulfilled]: (state, { payload }) => {
      state.coursePackageList = [];
      payload.data.results.forEach((item) => state.coursePackageList.push(item));
      state.childCount = payload.data.count;
      state.courseFetching = false;
      state.courseSuccess = true;
      return state;
    },
    [fetchCoursePackageList.rejected]: (state, action) => {
      state.courseFetching = false;
      state.courseError = true;
      state.courseErrorMessage = action?.payload;
    },
    [fetchCoursePackageList.pending]: (state) => {
      state.courseFetching = true;
    },
  },
});

export const { courseClearState } = coursePackageListSlice.actions;

export default coursePackageListSlice.reducer;
