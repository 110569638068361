import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchFeeCategoryDetails = createAsyncThunk(
  "admin/fee-category-details",
  async ({ feeCategoryID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.feeCategoryDetailsURL.replace("{id}", feeCategoryID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const feeCategoriesDetailsSlice = createSlice({
  name: "fee-category-details",
  initialState: {
    feeCategoryDetails: {},
    feeCategoryDetailsFetching: false,
    feeCategoryDetailsSuccess: false,
    feeCategoryDetailsError: false,
    feeCategoryDetailsErrorMessage: "",
  },
  reducers: {
    feeCategoryDetailsClearState: (state) => {
      state.feeCategoryDetailsError = false;
      state.feeCategoryDetailsSuccess = false;
      state.feeCategoryDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFeeCategoryDetails.fulfilled]: (state, { payload }) => {
      state.feeCategoryDetails = payload.data;
      state.feeCategoryDetailsFetching = false;
      state.feeCategoryDetailsSuccess = true;
      return state;
    },
    [fetchFeeCategoryDetails.rejected]: (state, { payload }) => {
      state.feeCategoryDetailsFetching = false;
      state.feeCategoryDetailsError = true;
      state.feeCategoryDetailsErrorMessage = payload?.message;
    },
    [fetchFeeCategoryDetails.pending]: (state) => {
      state.feeCategoryDetailsFetching = true;
    },
  },
});

export const { feeCategoryDetailsClearState } = feeCategoriesDetailsSlice.actions;

export default feeCategoriesDetailsSlice.reducer;
