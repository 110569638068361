import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchClassSettingDetails = createAsyncThunk(
  "admin/class-setting-details",
  async ({ classID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.classSettingDetailURL.replace("{id}", classID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const classSettingDetailsSlice = createSlice({
  name: "class-setting-details",
  initialState: {
    classSettingDetails: {},
    classSettingDetailsFetching: false,
    classSettingDetailsSuccess: false,
    classSettingDetailsError: false,
    classSettingDetailsErrorMessage: "",
  },
  reducers: {
    classSettingDetailsClearState: (state) => {
      state.classSettingDetailsError = false;
      state.classSettingDetailsSuccess = false;
      state.classSettingDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchClassSettingDetails.fulfilled]: (state, { payload }) => {
      state.classSettingDetails = payload.data;
      state.classSettingDetailsFetching = false;
      state.classSettingDetailsSuccess = true;
      return state;
    },
    [fetchClassSettingDetails.rejected]: (state, { payload }) => {
      state.classSettingDetailsFetching = false;
      state.classSettingDetailsError = true;
      state.classSettingDetailsErrorMessage = payload?.message;
    },
    [fetchClassSettingDetails.pending]: (state) => {
      state.classSettingDetailsFetching = true;
    },
  },
});

export const { classSettingDetailsClearState } = classSettingDetailsSlice.actions;

export default classSettingDetailsSlice.reducer;
