import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchStateList = createAsyncThunk(
  "/common/state/",
  async ({ country}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.stateListURL.replace("{country}", country),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const stateListSlice = createSlice({
  name: "state-list",
  initialState: {
    stateList: [],
    stateCount: null,
    stateFetching: false,
    stateSuccess: false,
    stateError: false,
    stateErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.stateError = false;
      state.stateSuccess = false;
      state.stateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStateList.fulfilled]: (state, { payload }) => {
      state.stateList = [];
      payload.data.results.forEach((item) => state.stateList.push(item));
      state.stateCount = payload.data.count;

      state.stateFetching = false;
      state.stateSuccess = true;
      return state;
    },
    [fetchStateList.rejected]: (state, action) => {
      state.stateFetching = false;
      state.stateError = true;
      state.stateErrorMessage = action?.payload;
    },
    [fetchStateList.pending]: (state) => {
      state.stateFetching = true;
    },
  },
});

export const { fclClearState } = stateListSlice.actions;

export default stateListSlice.reducer;
