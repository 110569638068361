import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";
export const fetchFormDataDetails = createAsyncThunk(
  "admin/formData-details",
  async ({ fieldID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.customFormFieldsDetailURL.replace("{id}", fieldID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const formDataDetailsSlice = createSlice({
  name: "formData-details",
  initialState: {
    formDataDetails: {},
    formDataDetailsFetching: false,
    formDataDetailsSuccess: false,
    formDataDetailsError: false,
    formDataDetailsErrorMessage: "",
  },
  reducers: {
    clearFormDataDetailsState: (state) => {
      state.formDataDetailsError = false;
      state.formDataDetailsSuccess = false;
      state.formDataDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFormDataDetails.fulfilled]: (state, { payload }) => {
      state.formDataDetails = payload.data;

      state.formDataDetailsFetching = false;
      state.formDataDetailsSuccess = true;
      return state;
    },
    [fetchFormDataDetails.rejected]: (state, action) => {
      state.formDataDetailsFetching = false;
      state.formDataDetailsError = true;
      state.formDataDetailsErrorMessage = action?.payload;
    },
    [fetchFormDataDetails.pending]: (state) => {
      state.formDataDetailsFetching = true;
    },
  },
});
export const { clearFormDataDetailsState } = formDataDetailsSlice.actions;

export default formDataDetailsSlice.reducer;
