import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchFormDataList = createAsyncThunk(
  "admin/form-data-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.customFormFieldsListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const formDataListSlice = createSlice({
  name: "form-data-list",
  initialState: {
    formDataList: [],
    formDataListFetching: false,
    formDataListSuccess: false,
    formDataListError: false,
    formDataListErrorMessage: "",
    formDataCount:null,
  },
  reducers: {
    formDataListClearState: (state) => {
      state.formDataListError = false;
      state.formDataListSuccess = false;
      state.formDataListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFormDataList.fulfilled]: (state, { payload }) => {
      state.formDataList = [];
      payload.data.results.forEach((item) => state.formDataList.push(item));
      state.formDataCount = payload.data.count;
      state.formDataListFetching = false;
      state.formDataListSuccess = true;
      return state;
    },
    [fetchFormDataList.rejected]: (state, action) => {
      state.formDataListFetching = false;
      state.formDataListError = true;
      state.formDataListErrorMessage = action?.payload;
    },
    [fetchFormDataList.pending]: (state) => {
      state.formDataListFetching = true;
    },
  },
});

export const { formDataListClearState } = formDataListSlice.actions;

export default formDataListSlice.reducer;
