import { memo, useState, Fragment, useEffect } from "react";

// header
import Headerpro from "../../components/partials/pro/headerstyle/header-pro";

//sidebar
import Sidebar from "../../components/partials/dashboard/sidebarstyle/sidebar";

//footer
import Footer from "../../components/partials/dashboard/footerstyle/footer";

//default
import DefaultRouter from "../../router/default-router";

//seetingoffCanvas
import SettingOffCanvas from "../../components/setting/SettingOffCanvas";

import Loader from "../../components/Loader";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";
import { fetchEmailVerifyBanner } from "../../store/demoSettings/EmailVerification/emailVerifyBannerSlice";
// Redux Selector / Action
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import VerifyEmail from "../../views/settings/OtherSettings/verifyOptScreen";
import { Button } from "react-bootstrap";
import { fetchUserProfile } from "../../store/profile/userProfileSlice";
import { emailOtpSend ,clearEmailOtpSendState } from "../../store/demoSettings/EmailVerification/emailOtpSendSlice";

const Default = memo((props) => {
  const history = useHistory();
  const pageLayout = useSelector(SettingSelector.page_layout);
  const appName = useSelector(SettingSelector.app_name);

  var subHeader = "";
  var commanclass = "";

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(fetchUserProfile());
    // dispatch(fetchEmailVerifyBanner());
  }, []);

  // const { userProfile } = useSelector((state) => state.userProfileSlice);

  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  // const { emailBanner } = useSelector((state) => state.emailVerifyBannerSlice);

  let handleSubmit = (e) => {
    e.preventDefault();

    // let payload = {
    //   email: userProfile?.email,
    // };
    // dispatch(emailOtpSend({ payload }));
    
  };
  // const { emailOtpSendSuccess, emailOtpSendError } = useSelector(
  //   (state) => state.emailOtpSendSlice
  // );

  // useEffect(() => {
  //   if (emailOtpSendSuccess) {
  //     setShowVerifyEmail(true);
  //     dispatch(clearEmailOtpSendState());
  //   }
  // }, [emailOtpSendSuccess]);

  return (
    <Fragment>
      <VerifyEmail toggle={showVerifyEmail} setToggle={setShowVerifyEmail} />
      <Loader />
      <Sidebar app_name={appName} />
      <main className={`main-content`} style={{ backgroundColor: "#f9f9f9" }}>
        <div className={`${commanclass} position-relative `}>
          <Headerpro />
          {subHeader}
        </div>

        <div className={` ${pageLayout} content-inner pb-0 `}>
          {/* {emailBanner !== null && emailBanner !== undefined && (
            <div
              className="alert  d-flex align-items-center alert-dismissible fade show"
              role="alert"
              style={{ backgroundColor: "#ffecbf" }}
            >
              <div className="text-black">
                {emailBanner} to continue
                <span
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  className=" ms-2 text-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  Verify Now
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 21 15"
                    fill="#008BCE"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.8706 14.6479C14.0926 14.5437 20.4707 8.15322 20.554 7.95147C20.6332 7.75948 20.6285 7.58419 20.539 7.39315C20.4887 7.28584 19.4431 6.21679 17.2072 3.98657C14.5611 1.34718 13.9203 0.727369 13.7976 0.688512C13.3182 0.536755 12.8276 0.939367 12.8865 1.43619C12.8991 1.54268 12.9482 1.68427 12.9957 1.75088C13.0431 1.81746 14.2356 3.0213 15.6459 4.42605L18.2098 6.98014L9.68768 6.98225C1.40172 6.98432 1.16103 6.98647 1.002 7.05864C0.477344 7.29687 0.479923 8.07687 1.00611 8.31588C1.15974 8.38566 1.47635 8.38832 9.68729 8.38914L18.209 8.39L15.5847 11.019C13.4629 13.1446 12.9513 13.6768 12.9125 13.7991C12.8093 14.1247 12.9671 14.4913 13.2744 14.64C13.4846 14.7418 13.6653 14.7441 13.8706 14.6479Z"
                      fill="#008BCE"
                    />
                  </svg>
                </span>
              </div>
            </div>
          )} */}
          <DefaultRouter />
        </div>
        <Footer />
      </main>
      <SettingOffCanvas />
    </Fragment>
  );
});

Default.displayName = "Default";
export default Default;
