import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchTransactionHistoryList = createAsyncThunk(
  "admin/transaction -history-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionHistoryListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const transactionHistoryListSlice = createSlice({
  name: "transaction-history-list",
  initialState: {
    transactionHistoryList: [],
    transactionHistoryListFetching: false,
    transactionHistoryListSuccess: false,
    transactionHistoryListError: false,
    transactionHistoryListErrorMessage: "",
    transactionHistoryCount: null,
  },
  reducers: {
    transactionHistoryListClearState: (state) => {
      state.transactionHistoryListError = false;
      state.transactionHistoryListSuccess = false;
      state.transactionHistoryListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTransactionHistoryList.fulfilled]: (state, { payload }) => {
      state.transactionHistoryList = [];
      payload.data.results.forEach((item) =>
        state.transactionHistoryList.push(item)
      );
      state.transactionHistoryCount = payload.data.count;
      state.transactionHistoryListFetching = false;
      state.transactionHistoryListSuccess = true;
      return state;
    },
    [fetchTransactionHistoryList.rejected]: (state, action) => {
      state.transactionHistoryListFetching = false;
      state.transactionHistoryListError = true;
      state.transactionHistoryListErrorMessage = action?.payload;
    },
    [fetchTransactionHistoryList.pending]: (state) => {
      state.transactionHistoryListFetching = true;
    },
  },
});

export const { transactionHistoryListClearState } = transactionHistoryListSlice.actions;

export default transactionHistoryListSlice.reducer;
