import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchMentorInfo = createAsyncThunk(
  "/admin/mentors-info/",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.mentorInfo,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const mentorInfoSlice = createSlice({
  name: "mentor-info",
  initialState: {
    mentorDetails: {},
    mentorFetching: false,
    mentorSuccess: false,
    mentorError: false,
    mentorErrorMessage: "",
  },
  reducers: {
    mentorClearState: (state) => {
      state.mentorError = false;
      state.mentorSuccess = false;
      state.mentorFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchMentorInfo.fulfilled]: (state, { payload }) => {
        console.log({payload});
      state.mentorDetails = payload.data;
      state.mentorFetching = false;
      state.mentorSuccess = true;
      return state;
    },
    [fetchMentorInfo.rejected]: (state, action) => {
      state.mentorFetching = false;
      state.mentorError = true;
      state.mentorErrorMessage = action?.payload;
    },
    [fetchMentorInfo.pending]: (state) => {
      state.mentorFetching = true;
    },
  },
});

export const { mentorClearState } = mentorInfoSlice.actions;

export default mentorInfoSlice.reducer;
