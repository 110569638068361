import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchFeesList = createAsyncThunk(
  "admin/fees-list",
  async ({ limit, offset, studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentFeeListURL
          .replace("{student_id}", studentID)
          .replace("{limit}", limit)
          .replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const feesListSlice = createSlice({
  name: "fees-list",
  initialState: {
    feesList: [],
    feesListFetching: false,
    feesListSuccess: false,
    feesListError: false,
    feesListErrorMessage: "",
    feesCount: null,
  },
  reducers: {
    feesListClearState: (state) => {
      state.feesListError = false;
      state.feesListSuccess = false;
      state.feesListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFeesList.fulfilled]: (state, { payload }) => {
      state.feesList = [];
      payload.data.results.forEach((item) => state.feesList.push(item));
      state.feesCount = payload.data.count;
      state.feesListFetching = false;
      state.feesListSuccess = true;
      return state;
    },
    [fetchFeesList.rejected]: (state, action) => {
      state.feesListFetching = false;
      state.feesListError = true;
      state.feesListErrorMessage = action?.payload;
    },
    [fetchFeesList.pending]: (state) => {
      state.feesListFetching = true;
    },
  },
});

export const { feesListClearState } = feesListSlice.actions;

export default feesListSlice.reducer;
