




import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchInitialFees = createAsyncThunk(
  "admin/settings/initial-fee-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.initialFeeListURL.replace("{limit}",limit).replace("{offset}",offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const initialFeeListSlice = createSlice({
  name: "initial-fee-list",
  initialState: {
    initialFee: [],
    initialFeeFetching: false,
    initialFeeSuccess: false,
    initialFeeError: false,
    initialFeeErrorMessage: "",
    initialFeeCount:null
  },
  reducers: {
    initialFeeClearState: (state) => {
      state.initialFeeError = false;
      state.initialFeeSuccess = false;
      state.initialFeeFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchInitialFees.fulfilled]: (state, { payload }) => {
      state.initialFee = [];
      payload.data.results.forEach((item) => state.initialFee.push(item));
      state.initialFeeCount=payload.data.count
      state.initialFeeFetching = false;
      state.initialFeeSuccess = true;
      return state;
    },
    [fetchInitialFees.rejected]: (state, action) => {
      state.initialFeeFetching = false;
      state.initialFeeError = true;
      state.initialFeeErrorMessage = action?.payload;
    },
    [fetchInitialFees.pending]: (state) => {
      state.initialFeeFetching = true;
    },
  },
});

export const { initialFeeClearState } = initialFeeListSlice.actions;

export default initialFeeListSlice.reducer;
