import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteCourse = createAsyncThunk(
  "admin/course-delete",
  async ({ courseID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.courseSettingsDetailsURL.replace("{id}", courseID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const courseDeleteSlice = createSlice({
  name: "course-delete",
  initialState: {
    courseDeleteFetching: false,
    courseDeleteSuccess: false,
    courseDeleteError: false,
    courseDeleteErrorMessage: "",
  },
  reducers: {
    courseDeleteClearState: (state) => {
      state.courseDeleteError = false;
      state.courseDeleteSuccess = false;
      state.courseDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteCourse.fulfilled]: (state, { payload }) => {
      state.courseDeleteFetching = false;
      state.courseDeleteSuccess = true;
      return state;
    },
    [deleteCourse.rejected]: (state, { payload }) => {
      state.courseDeleteFetching = false;
      state.courseDeleteError = true;
      state.courseDeleteErrorMessage = payload?.message;
    },
    [deleteCourse.pending]: (state) => {
      state.courseDeleteFetching = true;
    },
  },
});

export const { courseDeleteClearState } = courseDeleteSlice.actions;

export default courseDeleteSlice.reducer;
