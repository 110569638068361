import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchApplicationNumberDetails = createAsyncThunk(
  "admin/application-number-details",
  async (payload, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.applicationNumberDetailsURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const applicationNumberDetailsSlice = createSlice({
  name: "application-number-details",
  initialState: {
    applicationNumberDetails: {},
    applicationNumberDetailsFetching: false,
    applicationNumberDetailsSuccess: false,
    applicationNumberDetailsError: false,
    applicationNumberDetailsErrorMessage: "",
  },
  reducers: {
    applicationNumberDetailsClearState: (state) => {
      state.applicationNumberDetailsError = false;
      state.applicationNumberDetailsSuccess = false;
      state.applicationNumberDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchApplicationNumberDetails.fulfilled]: (state, { payload }) => {
      state.applicationNumberDetails = payload.data;
      state.applicationNumberDetailsFetching = false;
      state.applicationNumberDetailsSuccess = true;
      return state;
    },
    [fetchApplicationNumberDetails.rejected]: (state, { payload }) => {
      state.applicationNumberDetailsFetching = false;
      state.applicationNumberDetailsError = true;
      state.applicationNumberDetailsErrorMessage = payload?.message;
    },
    [fetchApplicationNumberDetails.pending]: (state) => {
      state.applicationNumberDetailsFetching = true;
    },
  },
});

export const { applicationNumberDetailsClearState } = applicationNumberDetailsSlice.actions;

export default applicationNumberDetailsSlice.reducer;
