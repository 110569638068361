import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchInitialFeeDetails = createAsyncThunk(
  "admin/initial-fee-details",
  async ({ initialFeeID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.initialFeeDetailsURL.replace("{id}", initialFeeID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialFeeDetailsSlice = createSlice({
  name: "initial-fee-details",
  initialState: {
    initialFeeDetails: {},
    initialFeeDetailsFetching: false,
    initialFeeDetailsSuccess: false,
    initialFeeDetailsError: false,
    initialFeeDetailsErrorMessage: "",
  },
  reducers: {
    initialFeeDetailsClearState: (state) => {
      state.initialFeeDetailsError = false;
      state.initialFeeDetailsSuccess = false;
      state.initialFeeDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchInitialFeeDetails.fulfilled]: (state, { payload }) => {
      state.initialFeeDetails = payload.data;
      state.initialFeeDetailsFetching = false;
      state.initialFeeDetailsSuccess = true;
      return state;
    },
    [fetchInitialFeeDetails.rejected]: (state, { payload }) => {
      state.initialFeeDetailsFetching = false;
      state.initialFeeDetailsError = true;
      state.initialFeeDetailsErrorMessage = payload?.message;
    },
    [fetchInitialFeeDetails.pending]: (state) => {
      state.initialFeeDetailsFetching = true;
    },
  },
});

export const { initialFeeDetailsClearState } = initialFeeDetailsSlice.actions;

export default initialFeeDetailsSlice.reducer;
