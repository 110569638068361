import axios from "axios";
import { toast } from "react-toastify";
const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";

  const token = localStorage.getItem("admin-token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  // axios.interceptors.request.use((request) => {
  //   return request;
  // });

  return new Promise((resolve, reject) => {
    payload.baseURL = "https://dev.enfono.com/api_albedo/api/v1/";

    axios(payload)
      .then((response) => {
        let resp = response.data;
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
        axios.interceptors.request.use((request) => {
          return request;
        });
        reject(getActionErrorMessage(err));
      });
  });
};

const getActionErrorMessage = (err) => {
  if (err.response?.data?.message) {
    return "Error: " + err.response.data.message;
  } else {
    return err;
  }
};

axios.interceptors.response.use(undefined, function (err) {
  console.log("enterde", err.config);
  var statusCode = err.status;
  if (statusCode == undefined) {
    // Server needs to specify CORS headers in the response
    // Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
    // Otherwise, these kinda issues happen

    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }

  return new Promise(() => {
    if (statusCode == 500) {
      toast.error(
        "Server is facing technical difficulties, Please try after some time."
      );
    } else if (statusCode == 401) {
      localStorage.removeItem("admin-token");
      window.location.pathname = "/auth/sign-in";
    } else if (statusCode == 426) {
      window.location.pathname = "/subscriptions";
    } else {
      throw err;
    }
  });
});

export default {
  /* auth URLs */
  loginURL: "auth/login/", // [POST]
  logoutURL: "auth/logout/", // [POST]
  profileURL: "auth/profile/", // [GET, PUT]
  existenceCheckURL: "auth/existence_checker/", // [POST]

  //Dashboard
  dashboardDataURL: "admin/dashboard/card-data/", // [GET]
  dashboardReportDetailsURL: "/admin/dashboard/financial_report/", // [GET]

  //Academy
  academyListURL: "utilities/academies/", // [GET]
  switchAcademyURL: "utilities/switch_academy/{id}/", // [PATCH]
  selectedAcademyURL: "utilities/academy/", // [GET]

  //Custom Form Fields
  customFormFieldsListURL: "utilities/extra_reg_fields/", // [GET]
  customFormFieldsPostURL: "utilities/extra_reg_fields/", // [POST]
  customFormFieldsDetailURL: "utilities/extra_reg_fields/{id}/", // [GET, PUT, DELETE]

  //Student
  studentListURL:
    "admin/students/list/?limit={limit}&offset={offset}&search={search}&status={status}&is_mentor={mentor}", // [GET]
  studentPostURL: "admin/students/",
  studentDetailURL: "admin/student/{id}/", // [GET, PUT, DELETE]
  tempStudentPostURL: "student/student/create_temp/", // [POST]
  studentDocumentListURL: "admin/student/{id}/files/", // [GET]
  studentDocumentDetailURL: "admin/student-file/{id}/", // [GET, PUT, DELETE]
  studentAttendanceListsURL: "student/attendance/?year={year}&month={month}",
  studentAttendanceDeleteURL: "student/mark_leave/{id}/", // [DELETE]
  markAbsentURL: "student/student/{id}/mark_leave/", // [POST]
  excelUploadURL: "admin/student/excel_upload/", // [POST]

  //Student Payments
  studentPaymentListURL:
    "admin/student/{id}/fee_payments_list/?limit={limit}&offset={offset}", // [GET]
  studentPaymentPostURL: "admin/student/{student_class_id}/fee_payments/", // [POST]
  studentPaymentDetailURL: "admin/fee_payment/{id}/", // [GET, PUT, DELETE]

  //Student Class
  studentClassListURL:
    "admin/student/{id}/class/?limit={limit}&offset={offset}", // [GET]
  studentClassPostURL: "admin/student/{id}/class/", // [POST]
  studentClassDetailURL: "admin/class/{id}/", // [GET, PUT, DELETE]
  studentClassUpdateURL: "admin/class/{id}/update/", // [GET, PUT, DELETE]
  //Student Course
  studentCourseListURL:
    "student/student/{id}/courses/?limit={limit}&offset={offset}", // [GET]
  studentCoursePostURL: "student/student/{id}/courses/", // [POST]
  studentCourseDetailURL: "student/student/{student_id}/course/{id}/", // [GET, PUT, DELETE]
  studentSubjectListURL:
    "admin/student/{student_id}/subjects/?limit={limit}&offset={offset}",
  studentUpcomingClassListURL:
    "admin/student/{student_id}/upcoming_classes/?limit={limit}&offset={offset}",
  studentUpcomingClass:
    "admin/students-upcoming-classes/?limit={limit}&offset={offset}",
  studentPendingFees: "admin/student/list-with-pending-fees/",
  //Student Fees
  studentFeeListURL:
    "admin/student/{student_id}/subject-fees/?limit={limit}&offset={offset}", // [GET]
  studentFeePostURL: "student/student/{id}/fees/", // [POST]
  studentFeeDetailURL: "student/student/{student_id}/fee/{id}/", // [GET, PUT, DELETE]
  //Assets
  assetAddURL: "utilities/assets/", // [POST]
  assetListURL:
    "utilities/assets/?search={search}&limit={limit}&offset={offset}", // [GET]
  assetDetailsURL: "utilities/asset/{id}/", // [GET, PUT, DELETE]
  assetEditURL: "utilities/asset/{id}/", // [GET, PUT, DELETE]
  assetDocumentListURL: "utilities/asset/{asset_id}/asset_documents/", // [GET]
  assetDocumentDetailURL: "utilities/asset/{asset_id}/asset_document/{id}/", // [GET, PUT, DELETE]

  //Leads
  leadListURL: "utilities/leads/?limit={limit}&offset={offset}", // [GET]
  leadAddURL: "utilities/leads/", // [POST]
  leadDetailsURL: "utilities/lead/{id}/", // [GET, PUT, DELETE]

  //HR
  userListURL: "admin/staffs/?search={search}&limit={limit}&offset={offset}", // [GET]
  userPostURL: "admin/staffs/", // [POST]
  userDetailsURL: "admin/staff/{id}/", // [GET, PUT, DELETE]
  attendanceListURL:
    "/admin/staff/attendance/report/?year={year}&month={month}", // [GET]
  leaveRequestListURL: "admin/staff/{id}/staff_attendance/", // [GET]
  leaveDeleteURL: "admin/staff_attendance/{id}/", // [DELETE]

  //Payroll
  salaryDetailsURL: "admin/staff_payroll/{id}/", // [GET, PUT, DELETE]
  salaryListURL:
    "admin/staff_payrolls/?limit={limit}&offset={offset}&month={month}&year={year}", // [GET]
  salaryAddURL: "admin/staff_payrolls/", // [POST]
  teacherPayroll:
    "/admin/teacher_payrolls/?limit={limit}&offset={offset}&month={month}&year={year}",
  teacherPayrollPostURL: "/admin/teacher_payrolls/",
  teacherPayrollDetailsURL: "/admin/teacher_payroll/{id}/",

  //Checkbook
  checkbookListURL: "admin/cheque_details/?limit={limit}&offset={offset}", // [GET]
  checkbookPostURL: "utilities/cheque_details/", // [POST]
  checkbookDetailsURL: "utilities/check_detail/{id}/", // [GET, PUT, DELETE]

  //Transaction
  transactionListURL:
    "admin/transactions/?type={type}&from_date={from_date}&to_date={to_date}&limit={limit}&offset={offset}&category={category}", // [GET]
  transactionCreateURL: "admin/transactions/", // [POST]
  transactionDetailsURL: "admin/transaction/{id}/", // [GET, PUT, DELETE]

  //Transfers
  transferListURL: "admin/transfer/?limit={limit}&offset={offset}", // [GET]
  transferPostURL: "admin/transfer/", // [POST]
  transferDetailsURL: "admin/transfer/{id}/", // [GET, PUT, DELETE]

  //Settings
  //Bank Accounts
  bankAccountListURL: "admin/bank_accounts/", // [GET]
  bankAccountDetailsURL: "admin/bank_account/{id}/", // [GET, PUT, DELETE]

  //Business Details
  businessDetailsListURL: "admin/business-settings/", // [GET]
  businessDetailsPatchURL: "admin/business-settings/", // [PATCH]

  //Transaction Categories
  transactionCategoriesURL: "admin/transaction_categories/?type={type}", // [GET]
  transactionCategoriesPostURL: "admin/transaction_categories/", // [POST]
  transactionCategoryDetailsURL: "admin/transaction_category/{id}/", // [GET, PUT, DELETE]

  //Transaction Sub Categories
  transactionSubCategoriesURL:
    "admin/transaction_category/{id}/sub_categories/?type={type}", // [GET]
  transactionSubCategoriesPostURL:
    "admin/transaction_category/{id}/sub_categories/", // [POST]
  transactionSubCategoryDetailsURL: "utilities/transaction_sub_category/{id}/", // [GET, PUT, DELETE]

  //Course Settings
  courseSettingsListURL: "utilities/courses/?limit={limit}&offset={offset}", // [GET]
  courseSettingsPostURL: "utilities/courses/", // [POST]
  courseSettingsDetailsURL: "utilities/course/{id}/", // [GET, PUT, DELETE]

  //Batch Settings
  batchSettingsListURL: "utilities/batches/?limit={limit}&offset={offset}", // [GET]
  batchSettingsPostURL: "utilities/batches/", // [POST]
  batchSettingsDetailsURL: "utilities/batch/{id}/", // [GET, PUT, DELETE]

  //Documents
  documentListURL: "utilities/documents/", // [GET]
  documentDetailsURL: "utilities/document/{id}/", // [GET, PUT, DELETE]

  //Subscriptions
  subscriptionListURL: "utilities/subscription/plans/", // [GET]
  extendSubscriptionURL: "utilities/subscription/extend/", // [POST]

  transactionHistoryListURL:
    "utilities/subscription/transactions/?limit={limit}&offset={offset}", // [GET]
  switchSubscriptionURL: "utilities/change_subscription_plan/", // [POST]

  dueAmountURL: "utilities/due_details/", // [GET]
  paymentVerifyURL: "utilities/payment/verify/", // [POST]
  bannerNotificationURL: "utilities/dashboard/banner/notification/", // [GET]

  //Reports
  reportsListURL:
    "admin/financial_report/?from_date={from_date}&to_date={to_date}", // [GET]
  staffAttendanceReportListURL:
    "utilities/ht/attendance/report/?limit={limit}&offset={offset}&month={month}&year={year}", // [GET]
  studentAttendanceReportListURL:
    "student/attendance/report/?limit={limit}&offset={offset}&month={month}&year={year}", // [GET]

  //Email Verification
  emailVerificationOtpSendURL: "auth/send_otp_for_email/", // [POST]
  emailVerificationOtpVerifyURL: "auth/verify_otp_for_email/", // [POST]
  emailVerificationBannerURL: "utilities/dashboard/email_verification_status/", // [GET]

  //Fee Settings
  feeCategoriesListURL:
    "utilities/fees_category/?limit={limit}&offset={offset}", // [GET]
  feeCategoriesPostURL: "utilities/fees_category/", // [POST]
  feeCategoryDetailsURL: "utilities/fees_category/{id}/", // [GET, PUT, DELETE]

  //Initial Fee Settings
  initialFeeListURL: "utilities/initial_fees/?limit={limit}&offset={offset}", // [GET]
  initialFeePostURL: "utilities/initial_fees/", // [POST]
  initialFeeDetailsURL: "utilities/initial_fee/{id}/", // [GET, PUT, DELETE]

  //Application Number
  applicationNumberListURL: "utilities/auto_generated_number/", // [GET]
  applicationNumberDetailsURL: "utilities/application_number/", // [GET, PUT, DELETE]

  //Account Report
  accountReportListURL: "admin/account_report/?upto_date={upto_date}", // [GET]

  //  mentor
  mentorListURL: "admin/mentors/?limit={limit}&offset={offset}&search={search}",
  addMentor: "admin/mentors/",
  mentorDetailURL: "admin/mentor/{id}/",
  mentorAssignedStudentsListURL:
    "admin/mentor/{mentor_id}/assigned_students/?limit={limit}&offset={offset}",
  mentorUnAssignedStudentsURL: "admin/student/{student_id}/unassign-mentor/",
  mentorAssignStudentURL:
    "admin/student/{student_id}/mentor/{mentor_id}/assign-mentor/",
  mentorStudentPendingFees:
    "admin/mentor-assigned-students/{mentor_id}/pending_fees/?limit={limit}&offset={offset}",
  mentorInfo: "/admin/mentor_profile/",

  // staff
  staffListURL: "admin/staffs/?limit={limit}&offset={offset}&search={search}",
  // other
  countryListURL: "common/countries/",
  districtListURL: "common/districts/?state={state}",
  stateListURL: "common/states/?country={country}",

  // subject
  subjectListURL: "admin/subjects/?limit={limit}&offset={offset}",
  subjectCreateURL: "admin/subjects/",
  subjectDetailURL: "admin/subject/{id}/",
  subjectFeesListURL: "admin/subject-fees/list/?limit={limit}&offset={offset}",
  subjectFeesDetailsURL: "admin/subject-fees/{subject_id}/subject-fees/",
  // teacher
  teacherListURL:
    "/admin/teachers/list/?limit={limit}&offset={offset}&search={search}&subject_id={subjectId}",
  teacherCreateURL: "/admin/teachers/",
  teacherDetailURL: "/admin/teacher/{id}/",
  teacherClassHistoryURL:
    "/admin/teacher/{teacher_id}/teacher-class-history/?limit={limit}&offset={offset}",
  teacherPaymentHistoryURL:
    "/admin/teacher/{teacher_id}/student-class-payment-history/?limit={limit}&offset={offset}",

  // syllabus
  syllabusListURL: "/admin/syllabus/?limit={limit}&offset={offset}",
  syllabusCreateURL: "/admin/syllabus/",
  syllabusDetailsURL: "/admin/syllabus/{id}/",

  // teacherRate
  teacherSubjectRateListURL:
    "/admin/teacher-subject-rates/?limit={limit}&offset={offset}&teacher_id={teacherId}&subject_id={subjectId}",
  teacherSubjectRateCreateURL: "/admin/teacher-subject-rates/",
  teacherSubjectRateDetailsURL: "/admin/teacher-subject-rate/{id}/",

  // exam mark
  examMarkListUrl: "/admin/exam-marks/?limit={limit}&offset={offset}",
  examMarkCreateURL: "/admin/exam-marks/",
  examMarkDetailsURL: "/admin/exam-marks/{id}/",
  coursePackageListURL: "/admin/course_packages/?limit={limit}&offset={offset}",
  coursePackageCreateURL: "/admin/course_packages/",
  coursePackageDetailsURL: "/admin/course_package/{id}/",
  studentCourseURL:
    "admin/student/{id}/student_course/?limit={limit}&offset={offset}",
    studentCourseCreateURL:"admin/student/{id}/student_course/",
  actionHandler,
};
