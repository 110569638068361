import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const checkExistence = createAsyncThunk(
  "admin/existence-check",
  async ({ checkData }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.existenceCheckURL,
        method: "POST",
        data: checkData,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const existenceCheckSlice = createSlice({
  name: "existence-check",
  initialState: {
    existenceCheckFetching: false,
    existenceCheckSuccess: false,
    existenceCheckError: false,
    existenceCheckErrorMessage: "",
    checkedData: null
  },
  reducers: {
    clearExistenceCheckState: (state) => {
      state.existenceCheckError = false;
      state.existenceCheckSuccess = false;
      state.existenceCheckFetching = false;
      state.existenceCheckErrorMessage = "";
      state.checkedData = null

      return state;
    },
  },
  extraReducers: {
    [checkExistence.fulfilled]: (state, { payload }) => {
      state.checkedData = payload.data;
      state.existenceCheckFetching = false;
      state.existenceCheckSuccess = true;
      return state;
    },
    [checkExistence.rejected]: (state, action) => {
      state.existenceCheckFetching = false;
      state.existenceCheckError = true;
      state.existenceCheckErrorMessage = action?.payload;
    },
    [checkExistence.pending]: (state) => {
      state.existenceCheckFetching = true;
    },
  },
});

export const { clearExistenceCheckState } = existenceCheckSlice.actions;

export default existenceCheckSlice.reducer;
