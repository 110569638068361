import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const teacherSubjectRateDelete = createAsyncThunk(
  "admin/delete-teacher-subject",
  async ({ deleteId }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.teacherSubjectRateDetailsURL.replace("{id}", deleteId),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const teacherSubjectRateDeleteSlice = createSlice({
  name: "delete-teacher-subject-rate",
  initialState: {
    teacherSubjectRateDeleteFetching: false,
    teacherSubjectRateDeleteSuccess: false,
    teacherSubjectRateDeleteError: false,
    teacherSubjectRateDeleteErrorMessage: "",
  },
  reducers: {
    teacherSubjectRateDeleteClearState: (state) => {
      state.teacherSubjectRateDeleteError = false;
      state.teacherSubjectRateDeleteSuccess = false;
      state.teacherSubjectRateDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [teacherSubjectRateDelete.fulfilled]: (state, { payload }) => {
      state.teacherSubjectRateDeleteFetching = false;
      state.teacherSubjectRateDeleteSuccess = true;
      return state;
    },
    [teacherSubjectRateDelete.rejected]: (state, action) => {
      state.teacherSubjectRateDeleteFetching = false;
      state.teacherSubjectRateDeleteError = true;
      state.teacherSubjectRateDeleteErrorMessage = action?.payload;
    },
    [teacherSubjectRateDelete.pending]: (state) => {
      state.teacherSubjectRateDeleteFetching = true;
    },
  },
});

export const { teacherSubjectRateDeleteClearState } = teacherSubjectRateDeleteSlice.actions;

export default teacherSubjectRateDeleteSlice.reducer;
