import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchSubscriptionsList = createAsyncThunk(
  "admin/subscriptions-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subscriptionListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const subscriptionsListSlice = createSlice({
  name: "subscriptions-list",
  initialState: {
    subscriptionsList: [],
    subscriptionListFetching: false,
    subscriptionListSuccess: false,
    subscriptionListError: false,
    subscriptionListErrorMessage: "",
    subscriptionsCount: null,
  },
  reducers: {
    subscriptionListClearState: (state) => {
      state.subscriptionListError = false;
      state.subscriptionListSuccess = false;
      state.subscriptionListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSubscriptionsList.fulfilled]: (state, { payload }) => {
      state.subscriptionsList = [];
      payload.data.results.forEach((item) =>
        state.subscriptionsList.push(item)
      );
      state.subscriptionsCount = payload.data.count;
      state.subscriptionListFetching = false;
      state.subscriptionListSuccess = true;
      return state;
    },
    [fetchSubscriptionsList.rejected]: (state, action) => {
      state.subscriptionListFetching = false;
      state.subscriptionListError = true;
      state.subscriptionListErrorMessage = action?.payload;
    },
    [fetchSubscriptionsList.pending]: (state) => {
      state.subscriptionListFetching = true;
    },
  },
});

export const { subscriptionListClearState } = subscriptionsListSlice.actions;

export default subscriptionsListSlice.reducer;
