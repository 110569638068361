import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchStudentPaymentDetails = createAsyncThunk(
  "admin/student-payment-details",
  async ({ paymentID  }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.studentPaymentDetailURL.replace("{id}", paymentID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const studentTransDetailsSlice = createSlice({
  name: "student-payment-details",
  initialState: {
    studentPaymentDetails: {},
    spdFetching: false,
    spdSuccess: false,
    spdError: false,
    spdErrorMessage: "",
  },
  reducers: {
    spdClearState: (state) => {
      state.spdError = false;
      state.spdSuccess = false;
      state.spdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentPaymentDetails.fulfilled]: (state, { payload }) => {
      state.studentPaymentDetails = payload.data;
      state.spdFetching = false;
      state.spdSuccess = true;
      return state;
    },
    [fetchStudentPaymentDetails.rejected]: (state, { payload }) => {
      state.spdFetching = false;
      state.spdError = true;
      state.spdErrorMessage = payload?.message;
    },
    [fetchStudentPaymentDetails.pending]: (state) => {
      state.spdFetching = true;
    },
  },
});

export const { spdClearState } = studentTransDetailsSlice.actions;

export default studentTransDetailsSlice.reducer;
