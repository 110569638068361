import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchTeacherPayrollList = createAsyncThunk(
  "admin/teacher-payroll-list",
  async ({ limit, offset,year,month }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:
          year === null || month === null
            ? api.teacherPayroll
                .replace("&month={month}", "")
                .replace("&year={year}", "")
                .replace("{offset}", offset)
                .replace("{limit}", limit)
            : api.teacherPayroll
                .replace("{month}", month)
                .replace("{year}", year)
                .replace("{offset}", offset)
                .replace("{limit}", limit),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const teacherPayrollListSlice = createSlice({
  name: "teacherPayroll-list",
  initialState: {
    teacherPayrollList: [],
    eslFetching: false,
    eslSuccess: false,
    eslError: false,
    eslErrorMessage: "",
    payrollCount: null,
  },
  reducers: {
    eslClearState: (state) => {
      state.eslError = false;
      state.eslSuccess = false;
      state.eslFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTeacherPayrollList.fulfilled]: (state, { payload }) => {
      state.teacherPayrollList = [];
      payload.data.results.forEach((item) =>
        state.teacherPayrollList.push(item)
      );
      state.payrollCount = payload.data.count;
      state.eslFetching = false;
      state.eslSuccess = true;
      return state;
    },
    [fetchTeacherPayrollList.rejected]: (state, action) => {
      state.eslFetching = false;
      state.eslError = true;
      state.eslErrorMessage = action?.payload;
    },
    [fetchTeacherPayrollList.pending]: (state) => {
      state.eslFetching = true;
    },
  },
});

export const { eslClearState } = teacherPayrollListSlice.actions;

export default teacherPayrollListSlice.reducer;
