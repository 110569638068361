import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const switchAcademy = createAsyncThunk(
  "admin/academy-edit",
  async ({ selectedID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.switchAcademyURL.replace("{id}", selectedID),
        method: "PATCH",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const academyEditSlice = createSlice({
  name: "academy-edit",
  initialState: {
    academyEditFetching: false,
    academyEditSuccess: false,
    academyEditError: false,
    academyEditErrorMessage: "",
  },
  reducers: {
    clearAcademyEditState: (state) => {
      state.academyEditError = false;
      state.academyEditSuccess = false;
      state.academyEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [switchAcademy.fulfilled]: (state, { payload }) => {
      state.academyEditFetching = false;
      state.academyEditSuccess = true;
      return state;
    },
    [switchAcademy.rejected]: (state, action) => {
      state.academyEditFetching = false;
      state.academyEditError = true;
      state.academyEditErrorMessage = action?.payload;
    },
    [switchAcademy.pending]: (state) => {
      state.academyEditFetching = true;
    },
  },
});
export const { clearAcademyEditState } = academyEditSlice.actions;

export default academyEditSlice.reducer;
