import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const syllabusDelete = createAsyncThunk(
  "admin/delete-syllabus",
  async ({ deleteId }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.syllabusDetailsURL.replace("{id}", deleteId),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const syllabusDeleteSlice = createSlice({
  name: "delete-syllabus",
  initialState: {
    syllabusDeleteFetching: false,
    syllabusDeleteSuccess: false,
    syllabusDeleteError: false,
    syllabusDeleteErrorMessage: "",
  },
  reducers: {
    syllabusDeleteClearState: (state) => {
      state.syllabusDeleteError = false;
      state.syllabusDeleteSuccess = false;
      state.syllabusDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [syllabusDelete.fulfilled]: (state, { payload }) => {
      state.syllabusDeleteFetching = false;
      state.syllabusDeleteSuccess = true;
      return state;
    },
    [syllabusDelete.rejected]: (state, action) => {
      state.syllabusDeleteFetching = false;
      state.syllabusDeleteError = true;
      state.syllabusDeleteErrorMessage = action?.payload;
    },
    [syllabusDelete.pending]: (state) => {
      state.syllabusDeleteFetching = true;
    },
  },
});

export const { syllabusDeleteClearState } = syllabusDeleteSlice.actions;

export default syllabusDeleteSlice.reducer;
