import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchFeeCategories = createAsyncThunk(
  "admin/settings/fee-category-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.feeCategoriesListURL.replace("{limit}",limit).replace("{offset}",offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const feeCategoriesListSlice = createSlice({
  name: "fee-category-list",
  initialState: {
    feeCategories: [],
    feeCategoryListFetching: false,
    feeCategoryListSuccess: false,
    feeCategoryListError: false,
    feeCategoryListErrorMessage: "",
    feeCategoryCount:null
  },
  reducers: {
    feeCategoryListClearState: (state) => {
      state.feeCategoryListError = false;
      state.feeCategoryListSuccess = false;
      state.feeCategoryListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFeeCategories.fulfilled]: (state, { payload }) => {
      state.feeCategories = [];
      payload.data.results.forEach((item) => state.feeCategories.push(item));
      state.feeCategoryCount=payload.data.count
      state.feeCategoryListFetching = false;
      state.feeCategoryListSuccess = true;
      return state;
    },
    [fetchFeeCategories.rejected]: (state, action) => {
      state.feeCategoryListFetching = false;
      state.feeCategoryListError = true;
      state.feeCategoryListErrorMessage = action?.payload;
    },
    [fetchFeeCategories.pending]: (state) => {
      state.feeCategoryListFetching = true;
    },
  },
});

export const { feeCategoryListClearState } = feeCategoriesListSlice.actions;

export default feeCategoriesListSlice.reducer;
