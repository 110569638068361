import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchStudentPayments = createAsyncThunk(
  "admin/student-payments",
  async ({ studentID, limit, offset }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentPaymentListURL
          .replace("{id}", studentID)
          .replace("{limit}", limit)
          .replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const studentTransListSlice = createSlice({
  name: "student-payments",
  initialState: {
    paymentList: [],
    stlFetching: false,
    stlSuccess: false,
    stlError: false,
    stlErrorMessage: "",
  },
  reducers: {
    stlClearState: (state) => {
      state.stlError = false;
      state.stlSuccess = false;
      state.stlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentPayments.fulfilled]: (state, { payload }) => {
      state.paymentList = [];
      payload.data.results.forEach((item) => state.paymentList.push(item));

      state.stlFetching = false;
      state.stlSuccess = true;
      return state;
    },
    [fetchStudentPayments.rejected]: (state, action) => {
      state.stlFetching = false;
      state.stlError = true;
      state.stlErrorMessage = action?.payload;
    },
    [fetchStudentPayments.pending]: (state) => {
      state.stlFetching = true;
    },
  },
});

export const { stlClearState } = studentTransListSlice.actions;

export default studentTransListSlice.reducer;
