import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const teacherDelete = createAsyncThunk(
  "admin/delete-teacher",
  async ({ deleteId }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.teacherDetailURL.replace("{id}", deleteId),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const teacherDeleteSlice = createSlice({
  name: "delete-teacher",
  initialState: {
    teacherDeleteFetching: false,
    teacherDeleteSuccess: false,
    teacherDeleteError: false,
    teacherDeleteErrorMessage: "",
  },
  reducers: {
    teacherDeleteClearState: (state) => {
      state.teacherDeleteError = false;
      state.teacherDeleteSuccess = false;
      state.teacherDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [teacherDelete.fulfilled]: (state, { payload }) => {
      state.teacherDeleteFetching = false;
      state.teacherDeleteSuccess = true;
      return state;
    },
    [teacherDelete.rejected]: (state, action) => {
      state.teacherDeleteFetching = false;
      state.teacherDeleteError = true;
      state.teacherDeleteErrorMessage = action?.payload;
    },
    [teacherDelete.pending]: (state) => {
      state.teacherDeleteFetching = true;
    },
  },
});

export const { teacherDeleteClearState } = teacherDeleteSlice.actions;

export default teacherDeleteSlice.reducer;
