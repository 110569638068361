import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchSelectedAcademy = createAsyncThunk(
  "admin/selected-academy",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.selectedAcademyURL,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const selectedAcademySlice = createSlice({
  name: "selected-academy",
  initialState: {
    selectedAcademy: {},
    selectedDataFetching: false,
    selectedDataSuccess: false,
    selectedDataError: false,
    selectedDataErrorMessage: "",
  },
  reducers: {
    clearSelectedDataState: (state) => {
      state.selectedDataError = false;
      state.selectedDataSuccess = false;
      state.selectedDataFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSelectedAcademy.fulfilled]: (state, { payload }) => {
      state.selectedAcademy = payload.data;

      state.selectedDataFetching = false;
      state.selectedDataSuccess = true;
      return state;
    },
    [fetchSelectedAcademy.rejected]: (state, action) => {
      state.selectedDataFetching = false;
      state.selectedDataError = true;
      state.selectedDataErrorMessage = action?.payload;
    },
    [fetchSelectedAcademy.pending]: (state) => {
      state.selectedDataFetching = true;
    },
  },
});
export const { clearSelectedDataState } = selectedAcademySlice.actions;

export default selectedAcademySlice.reducer;
