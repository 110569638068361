import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";
export const fetchFeesDetails = createAsyncThunk(
  "admin/fees-details",
  async ({ feesID ,studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentFeeDetailURL.replace("{student_id}",studentID).replace("{id}", feesID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const feesDetailsSlice = createSlice({
  name: "fees-details",
  initialState: {
    feesDetails: {},
    feeDetailsFetching: false,
    feeDetailsSuccess: false,
    feeDetailsError: false,
    feeDetailsErrorMessage: "",
  },
  reducers: {
    clearFeeDetailsState: (state) => {
      state.feeDetailsError = false;
      state.feeDetailsSuccess = false;
      state.feeDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFeesDetails.fulfilled]: (state, { payload }) => {
      state.feesDetails = payload.data;

      state.feeDetailsFetching = false;
      state.feeDetailsSuccess = true;
      return state;
    },
    [fetchFeesDetails.rejected]: (state, action) => {
      state.feeDetailsFetching = false;
      state.feeDetailsError = true;
      state.feeDetailsErrorMessage = action?.payload;
    },
    [fetchFeesDetails.pending]: (state) => {
      state.feeDetailsFetching = true;
    },
  },
});
export const { clearFeeDetailsState } = feesDetailsSlice.actions;

export default feesDetailsSlice.reducer;
