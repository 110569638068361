import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteStudentPayment = createAsyncThunk(
  "admin/student-payment-delete",
  async ({ paymentID  }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.studentPaymentDetailURL.replace("{id}", paymentID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const studentTransDeleteSlice = createSlice({
  name: "student-payment-delete",
  initialState: {
    spddFetching: false,
    spddSuccess: false,
    spddError: false,
    spddErrorMessage: "",
  },
  reducers: {
    spddClearState: (state) => {
      state.spddError = false;
      state.spddSuccess = false;
      state.spddFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteStudentPayment.fulfilled]: (state, { payload }) => {
      state.spddFetching = false;
      state.spddSuccess = true;
      return state;
    },
    [deleteStudentPayment.rejected]: (state, { payload }) => {
      state.spddFetching = false;
      state.spddError = true;
      state.spddErrorMessage = payload?.message;
    },
    [deleteStudentPayment.pending]: (state) => {
      state.spddFetching = true;
    },
  },
});

export const { spddClearState } = studentTransDeleteSlice.actions;

export default studentTransDeleteSlice.reducer;
