import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createTeacherSubjectRate = createAsyncThunk(
  "admin/create-teacher-subject",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.teacherSubjectRateCreateURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const teacherSubjectRateCreateSlice = createSlice({
  name: "create-teacher-subject-rate",
  initialState: {
    subjectCreateFetching: false,
    subjectCreateSuccess: false,
    subjectCreateError: false,
    subjectCreateErrorMessage: "",
  },
  reducers: {
    clearSubjectCreateState: (state) => {
      state.subjectCreateError = false;
      state.subjectCreateSuccess = false;
      state.subjectCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createTeacherSubjectRate.fulfilled]: (state, { payload }) => {
      state.subjectCreateFetching = false;
      state.subjectCreateSuccess = true;
      return state;
    },
    [createTeacherSubjectRate.rejected]: (state, action) => {
      state.subjectCreateFetching = false;
      state.subjectCreateError = true;
      state.subjectCreateErrorMessage = action?.payload;
    },
    [createTeacherSubjectRate.pending]: (state) => {
      state.subjectCreateFetching = true;
    },
  },
});

export const { clearSubjectCreateState } = teacherSubjectRateCreateSlice.actions;

export default teacherSubjectRateCreateSlice.reducer;
