import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchStudentPendingFees = createAsyncThunk(
  "admin/student-pending-fees",
  async ({ limit, offset, search, status, mentor }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:
          search == "" && status == ""
            ? api.studentPendingFees
                .replace("&search={search}", "")
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("&status={status}", "")
                .replace("{mentor}", mentor)
            : search == ""
            ? api.studentPendingFees
                .replace("&search={search}", "")
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("{status}", status)
                .replace("{mentor}", mentor)
            : status == ""
            ? api.studentPendingFees
                .replace("{search}", search)
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("&status={status}", "")
                .replace("{mentor}", mentor)
            : api.studentPendingFees
                .replace("{search}", search)
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("{status}", status)
                .replace("{mentor}", mentor),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const studentPendingFeesSlice = createSlice({
  name: "student-pending-fees",
  initialState: {
    studentList: [],
    childCount: null,
    fclFetching: false,
    fclSuccess: false,
    fclError: false,
    fclErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.fclError = false;
      state.fclSuccess = false;
      state.fclFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentPendingFees.fulfilled]: (state, { payload }) => {
      state.studentList = [];
      payload.data.results.forEach((item) => state.studentList.push(item));
      state.childCount = payload.data.count;

      state.fclFetching = false;
      state.fclSuccess = true;
      return state;
    },
    [fetchStudentPendingFees.rejected]: (state, action) => {
      state.fclFetching = false;
      state.fclError = true;
      state.fclErrorMessage = action?.payload;
    },
    [fetchStudentPendingFees.pending]: (state) => {
      state.fclFetching = true;
    },
  },
});

export const { fclClearState } = studentPendingFeesSlice.actions;

export default studentPendingFeesSlice.reducer;
