import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchStudentCourseDetails = createAsyncThunk(
  "admin/studentCourse-details",
  async ({ courseID ,studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:api.studentCourseDetailURL.replace("{student_id}",studentID).replace("{id}", courseID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const studentCourseDetailsSlice = createSlice({
  name: "studentCourse-details",
  initialState: {
    studentCourseDetails: {},
    studentCourseDetailsFetching: false,
    studentCourseDetailsSuccess: false,
    studentCourseDetailsError: false,
    studentCourseDetailsErrorMessage: "",
  },
  reducers: {
    clearStudentCourseDetailsState: (state) => {
      state.studentCourseDetailsError = false;
      state.studentCourseDetailsSuccess = false;
      state.studentCourseDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentCourseDetails.fulfilled]: (state, { payload }) => {
      state.studentCourseDetails = payload.data;

      state.studentCourseDetailsFetching = false;
      state.studentCourseDetailsSuccess = true;
      return state;
    },
    [fetchStudentCourseDetails.rejected]: (state, action) => {
      state.studentCourseDetailsFetching = false;
      state.studentCourseDetailsError = true;
      state.studentCourseDetailsErrorMessage = action?.payload;
    },
    [fetchStudentCourseDetails.pending]: (state) => {
      state.studentCourseDetailsFetching = true;
    },
  },
});
export const { clearStudentCourseDetailsState } = studentCourseDetailsSlice.actions;

export default studentCourseDetailsSlice.reducer;
