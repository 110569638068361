import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const childDocumentCreate = createAsyncThunk(
  "admin/student-document-create",
  async ({ payload , studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentDocumentListURL.replace("{id}", studentID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const childDocumentsCreateSlice = createSlice({
  name: "student-document-create",
  initialState: {
    sdcFetching: false,
    sdcSuccess: false,
    sdcError: false,
    sdcErrorMessage: "",
  },
  reducers: {
    clearSdcState: (state) => {
      state.sdcError = false;
      state.sdcSuccess = false;
      state.sdcFetching = false;

      state.sdcErrorMessage = "";

      return state;
    },
  },
  extraReducers: {
    [childDocumentCreate.fulfilled]: (state, { payload }) => {
      state.sdcFetching = false;
      state.sdcSuccess = true;
      return state;
    },
    [childDocumentCreate.rejected]: (state, action) => {
      state.sdcFetching = false;
      state.sdcError = true;
      state.sdcErrorMessage = action?.payload;
    },
    [childDocumentCreate.pending]: (state) => {
      state.sdcFetching = true;
    },
  },
});

export const { clearSdcState } = childDocumentsCreateSlice.actions;

export default childDocumentsCreateSlice.reducer;
