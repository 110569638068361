import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const switchSubscriptionPlan = createAsyncThunk(
  "admin/switch-plan",
  async ({payload}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.switchSubscriptionURL,
        method: "PUT",
        data: payload,
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const switchSubscriptionPlanSlice = createSlice({
  name: "switch-plan",
  initialState: {
    switchPlanFetching: false,
    switchPlanSuccess: false,
    switchPlanError: false,
    switchPlanErrorMessage: "",
  },
  reducers: {
    clearSwitchPlanState: (state) => {
      state.switchPlanError = false;
      state.switchPlanSuccess = false;
      state.switchPlanFetching = false;

      return state;
    },
  },
  extraReducers: {
    [switchSubscriptionPlan.fulfilled]: (state, { payload }) => {

      
      state.switchPlanFetching = false;
      state.switchPlanSuccess = true;
      return state;
    },
    [switchSubscriptionPlan.rejected]: (state, action) => {
      state.switchPlanFetching = false;
      state.switchPlanError = true;
      state.planDetailsErrorMessage = action?.payload;
    },
    [switchSubscriptionPlan.pending]: (state) => {
      state.planDetailsFetching = true;
    },
  },
});
export const { clearSwitchPlanState } = switchSubscriptionPlanSlice.actions;

export default switchSubscriptionPlanSlice.reducer;
