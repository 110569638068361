
import ReactDOM from "react-dom";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

//router
import { BrowserRouter } from "react-router-dom";
//store

import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//reducer
import { store } from "./store";

import "./i18n";

ReactDOM.render(
  <BrowserRouter basename="/">
    <Provider store={store}>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

