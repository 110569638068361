import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createTeacher = createAsyncThunk(
  "admin/create-teacher",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.teacherCreateURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const teacherCreateSlice = createSlice({
  name: "create-teacher",
  initialState: {
    teacherCreateFetching: false,
    teacherCreateSuccess: false,
    teacherCreateError: false,
    teacherCreateErrorMessage: "",
  },
  reducers: {
    clearTeacherCreateState: (state) => {
      state.teacherCreateError = false;
      state.teacherCreateSuccess = false;
      state.teacherCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createTeacher.fulfilled]: (state, { payload }) => {
      state.teacherCreateFetching = false;
      state.teacherCreateSuccess = true;
      return state;
    },
    [createTeacher.rejected]: (state, action) => {
      state.teacherCreateFetching = false;
      state.teacherCreateError = true;
      state.teacherCreateErrorMessage = action?.payload;
    },
    [createTeacher.pending]: (state) => {
      state.teacherCreateFetching = true;
    },
  },
});

export const { clearTeacherCreateState } = teacherCreateSlice.actions;

export default teacherCreateSlice.reducer;
