import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchBannerNotification = createAsyncThunk(
  "admin/banner-notification",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.bannerNotificationURL,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const bannerNotificationSlice = createSlice({
  name: "banner-notification",
  initialState: {
    bannerNotification:undefined,
    bannerNotificationFetching: false,
    bannerNotificationSuccess: false,
    bannerNotificationError: false,
    bannerNotificationErrorMessage: "",
  },
  reducers: {
    clearBannerNotificationState: (state) => {
      state.bannerNotificationError = false;
      state.bannerNotificationSuccess = false;
      state.bannerNotificationFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchBannerNotification.fulfilled]: (state, { payload }) => {
      state.bannerNotification = payload?.message;

      state.bannerNotificationFetching = false;
      state.bannerNotificationSuccess = true;
      return state;
    },
    [fetchBannerNotification.rejected]: (state, action) => {
      state.bannerNotificationFetching = false;
      state.bannerNotificationError = true;
      state.bannerNotificationErrorMessage = action?.payload;
    },
    [fetchBannerNotification.pending]: (state) => {
      state.bannerNotificationFetching = true;
    },
  },
});
export const { clearBannerNotificationState } = bannerNotificationSlice.actions;

export default bannerNotificationSlice.reducer;
