import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteClassSetting = createAsyncThunk(
  "admin/class-setting-delete",
  async ({ classID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.classSettingDetailURL.replace("{id}", classID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const classSettingDeleteSlice = createSlice({
  name: "class-setting-delete",
  initialState: {
    classSettingDeleteFetching: false,
    classSettingDeleteSuccess: false,
    classSettingDeleteError: false,
    classSettingDeleteErrorMessage: "",
  },
  reducers: {
    classSettingDeleteClearState: (state) => {
      state.classSettingDeleteError = false;
      state.classSettingDeleteSuccess = false;
      state.classSettingDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteClassSetting.fulfilled]: (state, { payload }) => {
      state.classSettingDeleteFetching = false;
      state.classSettingDeleteSuccess = true;
      return state;
    },
    [deleteClassSetting.rejected]: (state, { payload }) => {
      state.classSettingDeleteFetching = false;
      state.classSettingDeleteError = true;
      state.classSettingDeleteErrorMessage = payload?.message;
    },
    [deleteClassSetting.pending]: (state) => {
      state.classSettingDeleteFetching = true;
    },
  },
});

export const { classSettingDeleteClearState } = classSettingDeleteSlice.actions;

export default classSettingDeleteSlice.reducer;
