import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchStudentUpcomingList = createAsyncThunk(
  "admin/student-upcoming-list",
  async ({ limit, offset, studentID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentUpcomingClassListURL
          .replace("{limit}", limit)
          .replace("{offset}", offset)
          .replace("{student_id}", studentID),

        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const upcomingClassListSlice = createSlice({
  name: "student-upcoming-list",
  initialState: {
    studentComingList: [],
    childCount: null,
    fclFetching: false,
    fclSuccess: false,
    fclError: false,
    fclErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.fclError = false;
      state.fclSuccess = false;
      state.fclFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentUpcomingList.fulfilled]: (state, { payload }) => {
      state.studentComingList = [];
      payload.data.results.forEach((item) =>
        state.studentComingList.push(item)
      );
      state.childCount = payload.data.count;

      state.fclFetching = false;
      state.fclSuccess = true;
      return state;
    },
    [fetchStudentUpcomingList.rejected]: (state, action) => {
      state.fclFetching = false;
      state.fclError = true;
      state.fclErrorMessage = action?.payload;
    },
    [fetchStudentUpcomingList.pending]: (state) => {
      state.fclFetching = true;
    },
  },
});

export const { fclClearState } = upcomingClassListSlice.actions;

export default upcomingClassListSlice.reducer;
