import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchBatchList = createAsyncThunk(
  "admin/settings/batch-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.batchSettingsListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const batchListSlice = createSlice({
  name: "batch-list",
  initialState: {
    batchList: [],
    batchFetching: false,
    batchSuccess: false,
    batchError: false,
    batchErrorMessage: "",
    batchCount:null,
  },
  reducers: {
    batchClearState: (state) => {
      state.batchError = false;
      state.batchSuccess = false;
      state.batchFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchBatchList.fulfilled]: (state, { payload }) => {
      state.batchList = [];
      payload.data.results.forEach((item) => state.batchList.push(item));
      state.batchCount = payload.data.count;
      state.batchFetching = false;
      state.batchSuccess = true;
      return state;
    },
    [fetchBatchList.rejected]: (state, action) => {
      state.batchFetching = false;
      state.batchError = true;
      state.batchErrorMessage = action?.payload;
    },
    [fetchBatchList.pending]: (state) => {
      state.batchFetching = true;
    },
  },
});

export const { batchClearState } = batchListSlice.actions;

export default batchListSlice.reducer;
