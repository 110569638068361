import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editFeeCategory = createAsyncThunk(
  "admin/fee-category-edit",
  async ({ payload, feeCategoryID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.feeCategoryDetailsURL.replace("{id}", feeCategoryID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const feeCategoriesEditSlice = createSlice({
  name: "fee-category-edit",
  initialState: {
    feeCategoryEditFetching: false,
    feeCategoryEditSuccess: false,
    feeCategoryEditError: false,
    feeCategoryEditErrorMessage: "",
  },
  reducers: {
    feeCategoryEditClearState: (state) => {
      state.feeCategoryEditError = false;
      state.feeCategoryEditSuccess = false;
      state.feeCategoryEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editFeeCategory.fulfilled]: (state, { payload }) => {
      state.feeCategoryEditFetching = false;
      state.feeCategoryEditSuccess = true;
      return state;
    },
    [editFeeCategory.rejected]: (state, action) => {
      state.feeCategoryEditFetching = false;
      state.feeCategoryEditError = true;
      state.feeCategoryEditErrorMessage = action?.payload;
    },
    [editFeeCategory.pending]: (state) => {
      state.feeCategoryEditFetching = true;
    },
  },
});

export const { feeCategoryEditClearState } = feeCategoriesEditSlice.actions;

export default feeCategoriesEditSlice.reducer;
