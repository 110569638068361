import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchBatchDetails = createAsyncThunk(
  "admin/batch-details",
  async ({ batchID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.batchSettingsDetailsURL.replace("{id}", batchID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const batchDetailsSlice = createSlice({
  name: "batch-details",
  initialState: {
    batchDetails: {},
    batchDetailsFetching: false,
    batchDetailsSuccess: false,
    batchDetailsError: false,
    batchDetailsErrorMessage: "",
  },
  reducers: {
    batchDetailsClearState: (state) => {
      state.batchDetailsError = false;
      state.batchDetailsSuccess = false;
      state.batchDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchBatchDetails.fulfilled]: (state, { payload }) => {
      state.batchDetails = payload.data;
      state.batchDetailsFetching = false;
      state.batchDetailsSuccess = true;
      return state;
    },
    [fetchBatchDetails.rejected]: (state, { payload }) => {
      state.batchDetailsFetching = false;
      state.batchDetailsError = true;
      state.batchDetailsErrorMessage = payload?.message;
    },
    [fetchBatchDetails.pending]: (state) => {
      state.batchDetailsFetching = true;
    },
  },
});

export const { batchDetailsClearState } = batchDetailsSlice.actions;

export default batchDetailsSlice.reducer;
