import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchTeacherList = createAsyncThunk(
  "admin/teacher-list",
  async ({ limit, offset, search, subjectId }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: subjectId
          ? api.teacherListURL
              .replace("{search}", search)
              .replace("{limit}", limit)
              .replace("{offset}", offset)
              .replace("{subjectId}", subjectId)
          : api.teacherListURL
              .replace("{search}", search)
              .replace("{limit}", limit)
              .replace("{offset}", offset)
              .replace("&subject_id={subjectId}", ""),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const teacherListSlice = createSlice({
  name: "teacher-list",
  initialState: {
    teacherList: [],
    teacherCount: null,
    teacherFetching: false,
    teacherSuccess: false,
    teacherError: false,
    teacherErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.teacherError = false;
      state.teacherSuccess = false;
      state.teacherFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTeacherList.fulfilled]: (state, { payload }) => {
      state.teacherList = [];
      payload.data.results.forEach((item) => state.teacherList.push(item));
      state.teacherCount = payload.data.count;

      state.teacherFetching = false;
      state.teacherSuccess = true;
      return state;
    },
    [fetchTeacherList.rejected]: (state, action) => {
      state.teacherFetching = false;
      state.teacherError = true;
      state.teacherErrorMessage = action?.payload;
    },
    [fetchTeacherList.pending]: (state) => {
      state.teacherFetching = true;
    },
  },
});

export const { fclClearState } = teacherListSlice.actions;

export default teacherListSlice.reducer;
