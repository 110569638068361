import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchMentorList = createAsyncThunk(
  "/admin/mentors/",
  async ({ limit, offset, search }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:
          search === ""
            ? api.mentorListURL
                .replace("&search={search}", "")
                .replace("{offset}", offset).replace("{limit}", limit)
            : api.mentorListURL
                .replace("{offset}", offset)
                .replace("{search}", search)
                .replace("{limit}", limit),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const mentorListSlice = createSlice({
  name: "mentor-lists",
  initialState: {
    mentorList: [],
    childCount: null,
    mentorFetching: false,
    mentorSuccess: false,
    mentorError: false,
    mentorErrorMessage: "",
  },
  reducers: {
    mentorClearState: (state) => {
      state.mentorError = false;
      state.mentorSuccess = false;
      state.mentorFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchMentorList.fulfilled]: (state, { payload }) => {
      state.mentorList = [];
      payload.data.results.forEach((item) => state.mentorList.push(item));
      state.childCount = payload.data.count;
      state.mentorFetching = false;
      state.mentorSuccess = true;
      return state;
    },
    [fetchMentorList.rejected]: (state, action) => {
      state.mentorFetching = false;
      state.mentorError = true;
      state.mentorErrorMessage = action?.payload;
    },
    [fetchMentorList.pending]: (state) => {
      state.mentorFetching = true;
    },
  },
});

export const { mentorClearState } = mentorListSlice.actions;

export default mentorListSlice.reducer;
