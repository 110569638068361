import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchStudentClassList = createAsyncThunk(
  "admin/studentClass-list",
  async ({ limit, offset, studentID, status }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.studentClassListURL
          .replace("{id}", studentID)
          .replace("{limit}", limit)
          .replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const studentClassListSlice = createSlice({
  name: "studentClass-list",
  initialState: {
    studentClassList: [],
    studentClassListFetching: false,
    studentClassListSuccess: false,
    studentClassListError: false,
    studentClassListErrorMessage: "",
    studentClassCount: null,
  },
  reducers: {
    studentClassListClearState: (state) => {
      state.studentClassListError = false;
      state.studentClassListSuccess = false;
      state.studentClassListFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentClassList.fulfilled]: (state, { payload }) => {
      state.studentClassList = [];
      payload.data.results.forEach((item) => state.studentClassList.push(item));
      state.studentClassCount = payload.data.count;
      state.studentClassListFetching = false;
      state.studentClassListSuccess = true;
      return state;
    },
    [fetchStudentClassList.rejected]: (state, action) => {
      state.studentClassListFetching = false;
      state.studentClassListError = true;
      state.studentClassListErrorMessage = action?.payload;
    },
    [fetchStudentClassList.pending]: (state) => {
      state.studentClassListFetching = true;
    },
  },
});

export const { studentClassListClearState } = studentClassListSlice.actions;

export default studentClassListSlice.reducer;
