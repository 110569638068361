import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchStudentAttendanceList = createAsyncThunk(
  "admin/student-attendance-lists",
  async ({year, month}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:(year === null && month === null )? api.studentAttendanceListsURL.replace("?year={year}", "").replace("&month={month}", "")
        : api.studentAttendanceListsURL.replace("{year}", year).replace("{month}", month),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const attendanceListSlice = createSlice({
  name: "student-attendance-lists",
  initialState: {
    studentAttendanceList: [],
    studentAttendanceFetching: false,
    studentAttendanceSuccess: false,
    studentAttendanceError: false,
    studentAttendanceErrorMessage: "",
  },
  reducers: {
    studentAttendanceClearState: (state) => {
      state.studentAttendanceError = false;
      state.studentAttendanceSuccess = false;
      state.studentAttendanceFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentAttendanceList.fulfilled]: (state, { payload }) => {
      state.studentAttendanceList = [];
      payload.data.results.forEach((item) => state.studentAttendanceList.push(item));

      state.studentAttendanceFetching = false;
      state.studentAttendanceSuccess = true;
      return state;
    },
    [fetchStudentAttendanceList.rejected]: (state, action) => {
      state.studentAttendanceFetching = false;
      state.studentAttendanceError = true;
      state.studentAttendanceErrorMessage = action?.payload;
    },
    [fetchStudentAttendanceList.pending]: (state) => {
      state.studentAttendanceFetching = true;
    },
  },
});

export const { studentAttendanceClearState } = attendanceListSlice.actions;

export default attendanceListSlice.reducer;
