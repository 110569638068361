import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editApplicationNumber = createAsyncThunk(
  "admin/application-number-edit",
  async ({ payload }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.applicationNumberDetailsURL,
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const applicationNumberEditSlice = createSlice({
  name: "application-number-edit",
  initialState: {

    applicationNumberEditFetching: false,
    applicationNumberEditSuccess: false,
    applicationNumberEditError: false,
    applicationNumberEditErrorMessage: "",
  },
  reducers: {
    applicationNumberEditClearState: (state) => {
      state.applicationNumberEditError = false;
      state.applicationNumberEditSuccess = false;
      state.applicationNumberEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editApplicationNumber.fulfilled]: (state, { payload }) => {
      state.applicationNumberEditFetching = false;
      state.applicationNumberEditSuccess = true;
      return state;
    },
    [editApplicationNumber.rejected]: (state, { payload }) => {
      state.applicationNumberEditFetching = false;
      state.applicationNumberEditError = true;
      state.applicationNumberEditErrorMessage = payload?.message;
    },
    [editApplicationNumber.pending]: (state) => {
      state.applicationNumberEditFetching = true;
    },
  },
});

export const { applicationNumberEditClearState } = applicationNumberEditSlice.actions;

export default applicationNumberEditSlice.reducer;
