import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchTeacherClassHistory = createAsyncThunk(
  "admin/teacher-class-history",
  async ({ limit, offset, teacherId }, { rejectWithValue }) => { 
    try {
      const response = await api.actionHandler({
        url: api.teacherClassHistoryURL
          .replace("{teacher_id}", teacherId)
          .replace("{limit}", limit)
          .replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const teacherClassHistorySlice = createSlice({
  name: "teacherClassHistory-list",
  initialState: {
    teacherClassHistoryList: [],
    teacherClassHistoryCount: null,
    teacherClassHistoryFetching: false,
    teacherClassHistorySuccess: false,
    teacherClassHistoryError: false,
    teacherClassHistoryErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.teacherClassHistoryError = false;
      state.teacherClassHistorySuccess = false;
      state.teacherClassHistoryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTeacherClassHistory.fulfilled]: (state, { payload }) => {
      state.teacherClassHistoryList = [];
      payload.data.results.forEach((item) => state.teacherClassHistoryList.push(item));
      state.teacherClassHistoryCount = payload.data.count;

      state.teacherClassHistoryFetching = false;
      state.teacherClassHistorySuccess = true;
      return state;
    },
    [fetchTeacherClassHistory.rejected]: (state, action) => {
      state.teacherClassHistoryFetching = false;
      state.teacherClassHistoryError = true;
      state.teacherClassHistoryErrorMessage = action?.payload;
    },
    [fetchTeacherClassHistory.pending]: (state) => {
      state.teacherClassHistoryFetching = true;
    },
  },
});

export const { fclClearState } = teacherClassHistorySlice.actions;

export default teacherClassHistorySlice.reducer;
