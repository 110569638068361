import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createExamMark = createAsyncThunk(
  "admin/create-exam-mark",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.examMarkCreateURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const examMarkCreateSlice = createSlice({
  name: "exam-mark-create",
  initialState: {
    cvlFetching: false,
    cvlSuccess: false,
    cvlError: false,
    cvlErrorMessage: "",
  },
  reducers: {
    clearCvlState: (state) => {
      state.cvlError = false;
      state.cvlSuccess = false;
      state.cvlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createExamMark.fulfilled]: (state, { payload }) => {
      state.cvlFetching = false;
      state.cvlSuccess = true;
      return state;
    },
    [createExamMark.rejected]: (state, action) => {
      state.cvlFetching = false;
      state.cvlError = true;
      state.cvlErrorMessage = action?.payload;
    },
    [createExamMark.pending]: (state) => {
      state.cvlFetching = true;
    },
  },
});

export const { clearCvlState } = examMarkCreateSlice.actions;

export default examMarkCreateSlice.reducer;
