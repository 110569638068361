import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchTeacherSubjectRateList = createAsyncThunk(
  "admin/teacher-subject-fees-list",
  async ({ limit, offset, teacherId, subjectId }, { rejectWithValue }) => {
    console.log({ limit, offset, teacherId, subjectId });
    try {
      const response = await api.actionHandler({
        url:
          teacherId == "" && subjectId == ""
            ? api.teacherSubjectRateListURL
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("&teacher_id={teacherId}", "")
                .replace("&subject_id={subjectId}", "")
            : teacherId == "" && subjectId != ""
            ? api.teacherSubjectRateListURL
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("&teacher_id={teacherId}", "")
                .replace("{subjectId}", subjectId)
            : subjectId == "" && teacherId != ""
            ? api.teacherSubjectRateListURL
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("{teacherId}", teacherId)
                .replace("&subject_id={subjectId}", "")
            : api.teacherSubjectRateListURL
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("{teacherId}", teacherId)
                .replace("{subjectId}", subjectId),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const teacherSubjectFeesListSlice = createSlice({
  name: "teacher-subject-fees-list",
  initialState: {
    subjectFeesList: [],
    subjectCount: null,
    subjectFetching: false,
    subjectSuccess: false,
    subjectError: false,
    subjectErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.subjectError = false;
      state.subjectSuccess = false;
      state.subjectFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTeacherSubjectRateList.fulfilled]: (state, { payload }) => {
      state.subjectFeesList = [];
      payload.data.results.forEach((item) => state.subjectFeesList.push(item));
      state.subjectCount = payload.data.count;

      state.subjectFetching = false;
      state.subjectSuccess = true;
      return state;
    },
    [fetchTeacherSubjectRateList.rejected]: (state, action) => {
      state.subjectFetching = false;
      state.subjectError = true;
      state.subjectErrorMessage = action?.payload;
    },
    [fetchTeacherSubjectRateList.pending]: (state) => {
      state.subjectFetching = true;
    },
  },
});

export const { fclClearState } = teacherSubjectFeesListSlice.actions;

export default teacherSubjectFeesListSlice.reducer;
