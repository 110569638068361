import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchStudentAttendanceReport = createAsyncThunk(
  "admin/student-attendance-report-list",
  async ({ month, year ,limit ,offset }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:
          (month === null || year === null)
            ? api.studentAttendanceReportListURL.replace("&month={month}", "").replace("&year={year}", "").replace("{limit}", limit).replace("{offset}", offset)
            : api.studentAttendanceReportListURL.replace("{month}", month).replace("{year}", year).replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const studentAttendanceReportListSlice = createSlice({
  name: "student-attendance-report-list",
  initialState: {
    studentAttendanceReport: [],
    studentAttendanceReportFetching: false,
    studentAttendanceReportSuccess: false,
    studentAttendanceReportError: false,
      studentAttendanceReportErrorMessage: "",
    studentReportCount:null
  },
  reducers: {
    studentAttendanceReportClearState: (state) => {
      state.studentAttendanceReportError = false;
      state.studentAttendanceReportSuccess = false;
      state.studentAttendanceReportFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStudentAttendanceReport.fulfilled]: (state, { payload }) => {
      state.studentAttendanceReport = [];
       payload.data.results.forEach((item) => state.studentAttendanceReport.push(item));
       state.studentReportCount = payload.data.count;
      state.studentAttendanceReportFetching = false;
      state.studentAttendanceReportSuccess = true;
      return state;
    },
    [fetchStudentAttendanceReport.rejected]: (state, action) => {
      state.studentAttendanceReportFetching = false;
      state.studentAttendanceReportError = true;
      state.studentAttendanceReportErrorMessage = action?.payload;
    },
    [fetchStudentAttendanceReport.pending]: (state) => {
      state.studentAttendanceReportFetching = true;
    },
  },
});

export const { studentAttendanceReportClearState } = studentAttendanceReportListSlice.actions;

export default studentAttendanceReportListSlice.reducer;
