import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editStudentPayment = createAsyncThunk(
  "admin/student-payment-edit",
  async ({ paymentID , payload }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url:api.studentPaymentDetailURL.replace("{id}", paymentID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const studentTransEditSlice = createSlice({
  name: "student-payment-edit",
  initialState: {
    speFetching: false,
    speSuccess: false,
    speError: false,
    speErrorMessage: "",
  },
  reducers: {
    speClearState: (state) => {
      state.speError = false;
      state.speSuccess = false;
      state.speFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editStudentPayment.fulfilled]: (state, { payload }) => {
      state.speFetching = false;
      state.speSuccess = true;
      return state;
    },
    [editStudentPayment.rejected]: (state, { payload }) => {
      state.speFetching = false;
      state.speError = true;
      state.speErrorMessage = payload?.message;
    },
    [editStudentPayment.pending]: (state) => {
      state.speFetching = true;
    },
  },
});

export const { speClearState } = studentTransEditSlice.actions;

export default studentTransEditSlice.reducer;
