import React, { useEffect, useState } from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import OTPInput from "react-otp-input";
import "./verifyStyle.css";
import {
  emailOtpVerify,
  clearEmailOtpVerifyState,
} from "../../../store/demoSettings/EmailVerification/emailVerifyOtpSlice";
import { fetchEmailVerifyBanner } from "../../../store/demoSettings/EmailVerification/emailVerifyBannerSlice";
function VerifyEmail({ toggle, setToggle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  function handleChange(otp) {
    setOtp(otp);
  }

  let handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      otp,
    };

    dispatch(emailOtpVerify({ payload }));
  };

  const { emailOtpVerifySuccess, emailOtpVerifyError ,emailOtpVerifyErrorMessage } = useSelector(
    (state) => state.emailVerifyOtpSlice
  );

  useEffect(() => {
    if (emailOtpVerifySuccess) {
      setToggle(false);
      Swal.fire({
        title: "Success",
        text: "Email Verified Successfully",
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(clearEmailOtpVerifyState());
          dispatch(fetchEmailVerifyBanner());
        }
      });
    }
    else if (emailOtpVerifyError) {
      Swal.fire({
        title: "Error",
        text: emailOtpVerifyErrorMessage,
        icon: "error",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(clearEmailOtpVerifyState());
        }
      }
      );
    }
  }, [emailOtpVerifySuccess, emailOtpVerifyError]);
 

  return (
    <div>
      <div className="modal" id="modal">
        <Modal size="md" show={toggle} onHide={setToggle}>
          <Modal.Header closeButton>
            <Modal.Title>Verify Email</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-3">
            <p className="text-center text-black">
              An OTP has been sent to your entered email
            </p>
            <div className="otpElements">
              <p className="p3">Enter your Code here</p>
              <div className="otp">
                <OTPInput
                  onChange={handleChange}
                  value={otp}
                  inputStyle="inputStyle"
                  numInputs={4}
                  separator={<span></span>}
                />
              </div>
            </div>
          </Modal.Body>
          <Row className="mb-3">
            <Col className="d-flex justify-content-center align-items-center mt-3 me-3">
              <Button
                onClick={() => setToggle(false)}
                className="btn btn-md "
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => handleSubmit(e)}
                className="btn btn-md ms-3 bg-success text-white"
              >
                Verify
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
}

export default VerifyEmail;
