import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchLeadList = createAsyncThunk(
  "admin/lead-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.leadListURL.replace("{limit}", limit).replace("{offset}", offset) ,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const leadListSlice = createSlice({
  name: "lead-list",
  initialState: {
    leadList: [],
    vllFetching: false,
    vllSuccess: false,
    vllError: false,
    vllErrorMessage: "",
    leadCount:null,
  },
  reducers: {
    vllClearState: (state) => {
      state.vllError = false;
      state.vllSuccess = false;
      state.vllFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchLeadList.fulfilled]: (state, { payload }) => {
      state.leadList = [];
      payload.data.results.forEach((item) => state.leadList.push(item));
      state.leadCount = payload.data.count;
      state.vllFetching = false;
      state.vllSuccess = true;
      return state;
    },
    [fetchLeadList.rejected]: (state, action) => {
      state.vllFetching = false;
      state.vllError = true;
      state.vllErrorMessage = action?.payload;
    },
    [fetchLeadList.pending]: (state) => {
      state.vllFetching = true;
    },
  },
});

export const { vllClearState } = leadListSlice.actions;

export default leadListSlice.reducer;
