import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const markAbsent = createAsyncThunk(
  "admin/absent-add",
  async ({studentID, payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.markAbsentURL.replace("{id}",studentID),
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const markAbsentSlice = createSlice({
  name: "absent-add",
  initialState: {
    createLeaveFetching: false,
    createLeaveSuccess: false,
    createLeaveError: false,
    createLeaveErrorMessage: "",
    status:""
  },
  reducers: {
    clearCreateLeaveState: (state) => {
      state.createLeaveError = false;
      state.createLeaveSuccess = false;
      state.createLeaveFetching = false;
      

      return state;
    },
  },
  extraReducers: {
    [markAbsent.fulfilled]: (state, { payload }) => {
      state.createLeaveFetching = false;
      state.createLeaveSuccess = true;
      state.status = payload.status;
      return state;
    },
    [markAbsent.rejected]: (state, action) => {
      state.createLeaveFetching = false;
      state.createLeaveError = true;
      state.createLeaveErrorMessage = action?.payload;
    },
    [markAbsent.pending]: (state) => {
      state.createLeaveFetching = true;
    },
  },
});

export const { clearCreateLeaveState } = markAbsentSlice.actions;

export default markAbsentSlice.reducer;
