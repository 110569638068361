import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";
export const fetchEmailVerifyBanner = createAsyncThunk(
  "admin/banner-email",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.emailVerificationBannerURL,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const emailVerifyBannerSlice = createSlice({
  name: "banner-email",
  initialState: {
    emailBanner:undefined,
    emailBannerFetching: false,
    emailBannerSuccess: false,
    emailBannerError: false,
    emailBannerErrorMessage: "",
  },
  reducers: {
    clearEmailBannerState: (state) => {
      state.emailBannerError = false;
      state.emailBannerSuccess = false;
      state.emailBannerFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchEmailVerifyBanner.fulfilled]: (state, { payload }) => {
      state.emailBanner = payload?.message;

      state.emailBannerFetching = false;
      state.emailBannerSuccess = true;
      return state;
    },
    [fetchEmailVerifyBanner.rejected]: (state, action) => {
      state.emailBannerFetching = false;
      state.emailBannerError = true;
      state.emailBannerErrorMessage = action?.payload;
    },
    [fetchEmailVerifyBanner.pending]: (state) => {
      state.emailBannerFetching = true;
    },
  },
});
export const { clearEmailBannerState } = emailVerifyBannerSlice.actions;

export default emailVerifyBannerSlice.reducer;
