import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const createClassSetting = createAsyncThunk(
  "admin/settings/add-class-setting",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.classSettingPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const classSettingCreateSlice = createSlice({
  name: "add-class-setting",
  initialState: {
    classSettingCreateFetching: false,
    classSettingCreateSuccess: false,
    classSettingCreateError: false,
    classSettingCreateErrorMessage: "",
  },
  reducers: {
    classSettingCreateClearState: (state) => {
      state.classSettingCreateError = false;
      state.classSettingCreateSuccess = false;
      state.classSettingCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createClassSetting.fulfilled]: (state, { payload }) => {
      state.classSettingCreateFetching = false;
      state.classSettingCreateSuccess = true;
      return state;
    },
    [createClassSetting.rejected]: (state, action) => {
      state.classSettingCreateFetching = false;
      state.classSettingCreateError = true;
      state.classSettingCreateErrorMessage = action?.payload;
    },
    [createClassSetting.pending]: (state) => {
      state.classSettingCreateFetching = true;
    },
  },
});

export const { classSettingCreateClearState } = classSettingCreateSlice.actions;

export default classSettingCreateSlice.reducer;
