import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchCourseList = createAsyncThunk(
  "admin/settings/course-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.courseSettingsListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const courseListSlice = createSlice({
  name: "course-list",
  initialState: {
    courseList: [],
    courseFetching: false,
    courseSuccess: false,
    courseError: false,
    courseErrorMessage: "",
    courseCount:null,
  },
  reducers: {
    courseClearState: (state) => {
      state.courseError = false;
      state.courseSuccess = false;
      state.courseFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCourseList.fulfilled]: (state, { payload }) => {
      state.courseList = [];
      payload.data.results.forEach((item) => state.courseList.push(item));
      state.courseCount = payload.data.count;
      state.courseFetching = false;
      state.courseSuccess = true;
      return state;
    },
    [fetchCourseList.rejected]: (state, action) => {
      state.courseFetching = false;
      state.courseError = true;
      state.courseErrorMessage = action?.payload;
    },
    [fetchCourseList.pending]: (state) => {
      state.courseFetching = true;
    },
  },
});

export const { courseClearState } = courseListSlice.actions;

export default courseListSlice.reducer;
