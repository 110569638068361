import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editSubjectFees = createAsyncThunk(
  "admin/edit-subject-fees",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.subjectFeesDetailsURL.replace("{subject_id}",payload.id),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const subjectFeesEditSlice = createSlice({
  name: "edit-subject-fees",
  initialState: {
    subjectFeesEditFetching: false,
    subjectFeesEditSuccess: false,
    subjectFeesEditError: false,
    subjectFeesEditErrorMessage: "",
  },
  reducers: {
    clearSubjectFeesEditState: (state) => {
      state.subjectFeesEditError = false;
      state.subjectFeesEditSuccess = false;
      state.subjectFeesEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editSubjectFees.fulfilled]: (state, { payload }) => {
      state.subjectFeesEditFetching = false;
      state.subjectFeesEditSuccess = true;
      return state;
    },
    [editSubjectFees.rejected]: (state, action) => {
      state.subjectFeesEditFetching = false;
      state.subjectFeesEditError = true;
      state.subjectFeesEditErrorMessage = action?.payload;
    },
    [editSubjectFees.pending]: (state) => {
      state.subjectFeesEditFetching = true;
    },
  },
});

export const { clearSubjectFeesEditState } = subjectFeesEditSlice.actions;

export default subjectFeesEditSlice.reducer;
