import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteFeeCategory = createAsyncThunk(
  "admin/settings/delete-fee-category",
  async ({ feeCategoryID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.feeCategoryDetailsURL.replace("{id}", feeCategoryID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 204) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const feeCategoriesDeleteSlice = createSlice({
  name: "delete-fee-category",
  initialState: {
    deleteFeeCategoryFetching: false,
    deleteFeeCategorySuccess: false,
    deleteFeeCategoryError: false,
    deleteFeeCategoryErrorMessage: "",
  },
  reducers: {
    deleteFeeCategoryClearState: (state) => {
      state.deleteFeeCategoryError = false;
      state.deleteFeeCategorySuccess = false;
      state.deleteFeeCategoryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteFeeCategory.fulfilled]: (state, { payload }) => {
      state.deleteFeeCategoryFetching = false;
      state.deleteFeeCategorySuccess = true;
      return state;
    },
    [deleteFeeCategory.rejected]: (state, action) => {
      state.deleteFeeCategoryFetching = false;
      state.deleteFeeCategoryError = true;
      state.deleteFeeCategoryErrorMessage = action?.payload;
    },
    [deleteFeeCategory.pending]: (state) => {
      state.deleteFeeCategoryFetching = true;
    },
  },
});

export const { deleteFeeCategoryClearState } = feeCategoriesDeleteSlice.actions;

export default feeCategoriesDeleteSlice.reducer;
