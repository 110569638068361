import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchTransactionCategories = createAsyncThunk(
  "admin/settings/transaction-category-list",
  async ({type}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionCategoriesURL.replace("{type}", type),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const transCategoriesListSlice = createSlice({
  name: "transaction-category-list",
  initialState: {
    transCategories: [],
    tclFetching: false,
    tclSuccess: false,
    tclError: false,
    tclErrorMessage: "",
  },
  reducers: {
    tclClearState: (state) => {
      state.tclError = false;
      state.tclSuccess = false;
      state.tclFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTransactionCategories.fulfilled]: (state, { payload }) => {
      state.transCategories = [];
      payload.data.results.forEach((item) => state.transCategories.push(item));

      state.tclFetching = false;
      state.tclSuccess = true;
      return state;
    },
    [fetchTransactionCategories.rejected]: (state, action) => {
      state.tclFetching = false;
      state.tclError = true;
      state.tclErrorMessage = action?.payload;
    },
    [fetchTransactionCategories.pending]: (state) => {
      state.tclFetching = true;
    },
  },
});

export const { tclClearState } = transCategoriesListSlice.actions;

export default transCategoriesListSlice.reducer;
