import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchAttendanceDetails = createAsyncThunk(
  "admin/attendance-details",
  async ({ attendanceID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.attendanceDetailsURL.replace("{id}", attendanceID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const attendanceDetailsSlice = createSlice({
  name: "attendance-details",
  initialState: {
    attendanceDetails: {},
    fadFetching: false,
    fadSuccess: false,
    fadError: false,
    fadErrorMessage: "",
  },
  reducers: {
    clearFadState: (state) => {
      state.fadError = false;
      state.fadSuccess = false;
      state.fadFetching = false;

      state.fadErrorMessage = "";

      return state;
    },
  },
  extraReducers: {
    [fetchAttendanceDetails.fulfilled]: (state, { payload }) => {
      state.attendanceDetails = payload.data;

      state.fadFetching = false;
      state.fadSuccess = true;
      return state;
    },
    [fetchAttendanceDetails.rejected]: (state, action) => {
      state.fadFetching = false;
      state.fadError = true;
      state.fadErrorMessage = action?.payload;
    },
    [fetchAttendanceDetails.pending]: (state) => {
      state.fadFetching = true;
    },
  },
});
export const { clearFadState } = attendanceDetailsSlice.actions;

export default attendanceDetailsSlice.reducer;
