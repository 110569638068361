import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchMentorStudentPendingFeesList = createAsyncThunk(
  "/admin/mentors-student-pending-fees",
  async ({ mentorId ,limit,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.mentorStudentPendingFees
          .replace("{mentor_id}", mentorId)
          .replace("{offset}", offset)
          .replace("{limit}", limit),

        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const mentorStudentPendingFeesSlice = createSlice({
  name: "mentor-student-pending-fees",
  initialState: {
    mentorStudentPendingFeesList: [],
    childCount: null,
    mentorFetching: false,
    mentorSuccess: false,
    mentorError: false,
    mentorErrorMessage: "",
  },
  reducers: {
    mentorClearState: (state) => {
      state.mentorError = false;
      state.mentorSuccess = false;
      state.mentorFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchMentorStudentPendingFeesList.fulfilled]: (state, { payload }) => {
      state.mentorStudentPendingFeesList = [];
      payload.data.results.forEach((item) =>
        state.mentorStudentPendingFeesList.push(item)
      );
      state.childCount = payload.data.count;
      state.mentorFetching = false;
      state.mentorSuccess = true;
      return state;
    },
    [fetchMentorStudentPendingFeesList.rejected]: (state, action) => {
      state.mentorFetching = false;
      state.mentorError = true;
      state.mentorErrorMessage = action?.payload;
    },
    [fetchMentorStudentPendingFeesList.pending]: (state) => {
      state.mentorFetching = true;
    },
  },
});

export const { mentorClearState } = mentorStudentPendingFeesSlice.actions;

export default mentorStudentPendingFeesSlice.reducer;
