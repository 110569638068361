import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchSyllabusDetails = createAsyncThunk(
  "admin/syllabus-details",
  async ({ id }, { rejectWithValue }) => {
   
    try {
      const response = await api.actionHandler({
        url: api.syllabusDetailsURL.replace("{id}", id),
        method: "GET",
      });

      let data = await response;
      if (data.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const syllabusDetailsSlice = createSlice({
  name: "syllabus-details",
  initialState: {
    syllabusDetails: {},
    fldFetching: false,
    fldSuccess: false,
    fldError: false,
    fldErrorMessage: "",
  },
  reducers: {
    clearFldState: (state) => {
      state.fldError = false;
      state.fldSuccess = false;
      state.fldFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSyllabusDetails.fulfilled]: (state, { payload }) => {
      state.syllabusDetails = payload.data;

      state.fldFetching = false;
      state.fldSuccess = true;
      return state;
    },
    [fetchSyllabusDetails.rejected]: (state, action) => {
      state.fldFetching = false;
      state.fldError = true;
      state.fldErrorMessage = action?.payload;
    },
    [fetchSyllabusDetails.pending]: (state) => {
      state.fldFetching = true;
    },
  },
});

export const { clearFldState } = syllabusDetailsSlice.actions;

export default syllabusDetailsSlice.reducer;
