import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const emailOtpVerify = createAsyncThunk(
  "admin/email-verify",
  async ( {payload}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.emailVerificationOtpVerifyURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const emailVerifyOtpSlice = createSlice({
  name: "email-verify",
  initialState: {
    emailOtpVerifyFetching: false,
    emailOtpVerifySuccess: false,
    emailOtpVerifyError: false,
    emailOtpVerifyErrorMessage: "",
  },
  reducers: {
    clearEmailOtpVerifyState: (state) => {
      state.emailOtpVerifyError = false;
      state.emailOtpVerifySuccess = false;
      state.emailOtpVerifyFetching = false;

      return state;
    },
  },
  extraReducers: {
    [emailOtpVerify.fulfilled]: (state, { payload }) => {
      state.emailOtpVerifyFetching = false;
      state.emailOtpVerifySuccess = true;
      return state;
    },
    [emailOtpVerify.rejected]: (state, action) => {
      state.emailOtpVerifyFetching = false;
      state.emailOtpVerifyError = true;
      state.emailOtpVerifyErrorMessage = action?.payload;
    },
    [emailOtpVerify.pending]: (state) => {
      state.emailOtpVerifyFetching = true;
    },
  },
});

export const { clearEmailOtpVerifyState } = emailVerifyOtpSlice.actions;

export default emailVerifyOtpSlice.reducer;
