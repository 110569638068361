import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchAcademyList = createAsyncThunk(
  "admin/academy-list",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.academyListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const academyListSlice = createSlice({
  name: "academy-list",
  initialState: {
    academyList: [],
    academyFetching: false,
    academySuccess: false,
    academyError: false,
    academyErrorMessage: "",
    leadCount:null,
  },
  reducers: {
    academyClearState: (state) => {
      state.academyError = false;
      state.academySuccess = false;
      state.academyFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAcademyList.fulfilled]: (state, { payload }) => {
      state.academyList = [];
      payload.data.results.forEach((item) => state.academyList.push(item));
      state.leadCount = payload.data.count;
      state.academyFetching = false;
      state.academySuccess = true;
      return state;
    },
    [fetchAcademyList.rejected]: (state, action) => {
      state.academyFetching = false;
      state.academyError = true;
      state.academyErrorMessage = action?.payload;
    },
    [fetchAcademyList.pending]: (state) => {
      state.academyFetching = true;
    },
  },
});

export const { academyClearState } = academyListSlice.actions;

export default academyListSlice.reducer;
