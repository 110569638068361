import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

const Dashboard = lazy(() => import("../views/dashboard/index"));

//Students
const StudentDashboard = lazy(() =>
  import("../views/Students/StudentDashboard")
);
const AddStudent = lazy(() => import("../views/Students/AddStudent"));
const StudentProfileDashboard = lazy(() =>
  import("../views/Students/Dashboard/index")
);

const EditStudent = lazy(() => import("../views/Students/EditStudent"));

//Asset Management
const Assets = lazy(() => import("../views/Assets/index"));

//Leads
const Leads = lazy(() => import("../views/Leads/Index"));

//HR
const HR = lazy(() => import("../views/HR/index"));

//Cashbook
const ExpenseTransaction = lazy(() =>
  import("../views/cashbook/expense/index")
);
const IncomeTransaction = lazy(() => import("../views/cashbook/income/index"));
const Checkbook = lazy(() => import("../views/cashbook/checkbook/Checkbook"));
const Transfer = lazy(() => import("../views/cashbook/transfers/Transfers"));

//Documents
const Documents = lazy(() => import("../views//Documents/Documents"));

//Reports
const Reports = lazy(() => import("../views/reports/IncomevsExpense"));
const AttendanceReport = lazy(() =>
  import("../views/reports/AttendanceReport")
);

const TeacherReport = lazy(() =>
  import("../views/reports/TeacherReport/TeacherReport")
);
const TeacherReportDetails = lazy(() =>
  import("../views/reports/TeacherReport/TeacherReportDetails")
);

const StudentReport = lazy(() =>
  import("../views/reports/Student Report/StudentReport")
);

const StudentReportDetails = lazy(() =>
  import("../views/reports/Student Report/StudentReportDetails")
);
//Settings
const Settings = lazy(() => import("../views/settings/index"));

//Profile
const UserProfile = lazy(() => import("../views/Profile/ProfileDashboard"));

//Subscriptions
const Subscriptions = lazy(() => import("../views/Profile/Subscriptions"));
const PaymentHistory = lazy(() => import("../views/Profile/PaymentHistory"));

// mentor
const Mentor = lazy(() => import("../views/Mentor/Index"));

const MentorProfile = lazy(() => import("../views/Mentor/MentorProfile/Index"));

const MentorInfo = lazy(() => import("../views/Mentor/MentorInfo/Index"));

// teacher
const Teacher = lazy(() => import("../views/Teacher/TeacherList"));

const TeacherProfile = lazy(() =>
  import("../views/Teacher/TeacherProfile/Index")
);

//  exam mark
const ExamMark = lazy(() => import("../views/ExamMark/Index"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/general-documents" exact component={Documents} />

            <Route
              exact
              path="/student/:active_tab?"
              component={StudentDashboard}
            />
            <Route
              path="/student/student-list/add-student"
              component={AddStudent}
            />
            <Route
              path="/student/student-list/edit-student/:id"
              component={EditStudent}
            />
            <Route
              path="/student/student-list/:studentID/:active_tab?"
              component={StudentProfileDashboard}
            />

            <Route exact path="/assets" component={Assets} />
            <Route path="/Leads" component={Leads} />

            <Route exact path="/hr-management/:active_tab?" component={HR} />

            <Route path="/settings/:active_tab" component={Settings} />
            {/* mentor */}
            <Route exact path="/mentor" component={Mentor} />
            <Route exact path="/mentor/info" component={MentorInfo} />
            <Route
              exact
              path="/mentor/:id/:active_tab?"
              component={MentorProfile}
            />
            {/* teacher */}
            <Route exact path="/teacher" component={Teacher} />
            <Route
              exact
              path="/teacher/:id/:active_tab?"
              component={TeacherProfile}
            />

            {/*Cashbook */}
            <Route path="/cashbook/expenses" component={ExpenseTransaction} />
            <Route path="/cashbook/income" component={IncomeTransaction} />
            <Route path="/cashbook/chequebook" component={Checkbook} />
            <Route path="/cashbook/transfers" component={Transfer} />
            {/* Report */}
            <Route path="/report/finance" component={Reports} />
            <Route path="/report/attendance" component={AttendanceReport} />
            <Route path="/report/teacher" component={TeacherReport} />
            <Route path="/report/student" component={StudentReport} />
            <Route
              path="/report-teacher/:id/:active_tab?"
              component={TeacherReportDetails}
            />
            <Route
              path="/report-student/:id/:active_tab?"
              component={StudentReportDetails}
            />

            <Route path="/staff-profile" component={UserProfile} />
            <Route path="/exam-mark" component={ExamMark} />

            <Route path="/subscriptions" component={Subscriptions} />
            <Route path="/payment-history" component={PaymentHistory} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
