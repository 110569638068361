import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const planExtend = createAsyncThunk(
  "admin/plan-extend",
  async ( payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.extendSubscriptionURL,
        method: "POST",
        // data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const extendPlanPostSlice = createSlice({
  name: "plan-extend",
  initialState: {
    planExtendFetching: false,
    planExtendSuccess: false,
    planExtendError: false,
    planExtendErrorMessage: "",
  },
  reducers: {
    clearPlanExtendState: (state) => {
      state.planExtendError = false;
      state.planExtendSuccess = false;
      state.planExtendFetching = false;

      return state;
    },
  },
  extraReducers: {
    [planExtend.fulfilled]: (state, { payload }) => {
      state.planExtendFetching = false;
      state.planExtendSuccess = true;
      return state;
    },
    [planExtend.rejected]: (state, action) => {
      state.planExtendFetching = false;
      state.planExtendError = true;
      state.planExtendErrorMessage = action?.payload;
    },
    [planExtend.pending]: (state) => {
      state.planExtendFetching = true;
    },
  },
});

export const { clearPlanExtendState } = extendPlanPostSlice.actions;

export default extendPlanPostSlice.reducer;
