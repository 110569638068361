import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editTeacherDetails = createAsyncThunk(
  "admin/edit-teacher",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.teacherDetailURL.replace("{id}", payload.id),
        method: "PATCH",
        data:payload
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);

const teacherEditSlice = createSlice({
    name: "teacher-edit",
    initialState: {
      evlFetching: false,
      evlSuccess: false,
      evlError: false,
      evlErrorMessage: "",
    },
    reducers: {
      clearEvlState: (state) => {
        state.evlError = false;
        state.evlSuccess = false;
        state.evlFetching = false;
  
        return state;
      },
    },
    extraReducers: {
      [editTeacherDetails.fulfilled]: (state, { payload }) => {
        state.evlFetching = false;
        state.evlSuccess = true;
        return state;
      },
      [editTeacherDetails.rejected]: (state, action) => {
        state.evlFetching = false;
        state.evlError = true;
        state.evlErrorMessage = action?.payload;
      },
      [editTeacherDetails.pending]: (state) => {
        state.evlFetching = true;
      },
    },
  });

  export const { clearEvlState } = teacherEditSlice.actions;

  export default teacherEditSlice.reducer;
  