import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchSyllabusList = createAsyncThunk(
  "admin/syllabus-list",
  async ({ limit, offset }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.syllabusListURL

          .replace("{limit}", limit)
          .replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const syllabusListSlice = createSlice({
  name: "syllabus-list",
  initialState: {
    syllabusList: [],
    childCount: null,
    fclFetching: false,
    fclSuccess: false,
    fclError: false,
    fclErrorMessage: "",
  },
  reducers: {
    fclClearState: (state) => {
      state.fclError = false;
      state.fclSuccess = false;
      state.fclFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSyllabusList.fulfilled]: (state, { payload }) => {
      state.syllabusList = [];
      payload.data.results.forEach((item) => state.syllabusList.push(item));
      state.childCount = payload.data.count;

      state.fclFetching = false;
      state.fclSuccess = true;
      return state;
    },
    [fetchSyllabusList.rejected]: (state, action) => {
      state.fclFetching = false;
      state.fclError = true;
      state.fclErrorMessage = action?.payload;
    },
    [fetchSyllabusList.pending]: (state) => {
      state.fclFetching = true;
    },
  },
});

export const { fclClearState } = syllabusListSlice.actions;

export default syllabusListSlice.reducer;
